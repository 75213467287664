import React from 'react'
import colors from '../../utils/colors'
import ToggleSwitch from './ToggleSwitch'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

const LayerSidebar = ({ toggleLayer, isLayerVisible, header, layerName, layerLabel, layersData, layerMerge }) => {
  const { language } = useLanguage()

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        width: '90%',
        margin: '30px auto 0'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {header}
        </div>
      )}
      {layersData.map((layer, index) => (
        <ToggleSwitch
          key={index}
          isLayerVisible={layer.isLayerVisible[0]}
          toggleLayer={toggleLayer}
          layerName={layer.layerName}
          label={translate(layer.layerLabel, language)}
        />
      ))}
    </div>
  )
}

export default LayerSidebar
