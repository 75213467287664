import React from 'react'
import PropTypes from 'prop-types' // To validate props types
import colors from '../../utils/colors' // Assuming you already have a colors file
import KPIBar from '../KPIBar'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

// Function to build the bar data from overlay data
const buildBarData = (overlayData, language) => {
  if (!overlayData || !overlayData.properties) {
    return [] // Return an empty array if overlayData or properties are undefined
  }

  return [
    {
      barLabel: translate('invest_potential', language),
      value: overlayData.properties.invest_potential || 0, // Fallback to 0 if undefined
      barColor: colors.statusbar.cat1
    },
    {
      barLabel: translate('location', language),
      value: overlayData.properties.location || 0, // Fallback to 0 if undefined
      barColor: colors.statusbar.cat2
    },
    {
      barLabel: translate('life_quality', language),
      value: overlayData.properties.life_quality || 0, // Fallback to 0 if undefined
      barColor: colors.statusbar.cat3
    },
    {
      barLabel: translate('sustainability', language),
      value: overlayData.properties.sustainability || 0, // Fallback to 0 if undefined
      barColor: colors.statusbar.cat4
    }
  ]
}

// KPIBarsOverlay component to display the list of KPI bars
const KPIBarsOverlay = ({ overlayData, header }) => {
  const { language } = useLanguage()
  const barDataArray = buildBarData(overlayData, language)

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        margin: '0px auto 30px',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      {/* Render the header if provided */}
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText // Optionally use a different color for the header text
          }}
        >
          {header}
        </div>
      )}
      {barDataArray.map((barData, index) => (
        <KPIBar
          key={index} // Unique key for each bar
          barLabel={barData.barLabel}
          value={barData.value}
          barColors={barData.barColor}
          marginTop={index === 0 ? '0px' : '30px'} // Conditional marginTop
        />
      ))}
    </div>
  )
}

KPIBarsOverlay.propTypes = {
  overlayData: PropTypes.shape({
    properties: PropTypes.shape({
      invest_potential: PropTypes.number.isRequired,
      location: PropTypes.number.isRequired,
      life_quality: PropTypes.number.isRequired,
      sustainability: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
}

export default KPIBarsOverlay
