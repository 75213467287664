import React, { useState } from 'react'
import colors from '../../utils/colors'
import InputNumber from 'rc-input-number'
import 'rc-input-number/assets/index.css'
import './InputTextCalculator.css'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import HeaderWithInfoIcon from '../../components/HeaderWithInfoIcon'

const InputTextCalculator = ({
  parameter,
  value,
  min,
  max,
  steps,
  unit,
  setValue,
  minWidth
}) => {
  const { language } = useLanguage()

  // Local state to hold raw input
  const [inputValue, setInputValue] = useState(value)

  const changeTrigger = newValue => {
    setValue(parameter, newValue, steps, unit)
    console.log('New Value:', newValue)
  }

  // Function to format numbers in German notation
  const formatNumber = num => {
    if (num === undefined || num === null) return ''

    const numberValue = parseFloat(num)
    return unit === 'Jahr(e)'
    ? numberValue.toString() // No formatting for 'a' (years), just return the number as a string
    : unit === '%'
      ? (numberValue * 100).toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        })
      : numberValue.toLocaleString('de-DE', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2
        });
  }

  // Function to parse formatted numbers
  const parseNumber = str => {
    if (typeof str !== 'string') {
      // If the input is already a number, return it directly
      return str
    }
    if (unit === '%') {
      return (
        parseFloat(str.replace(' %', '').replace('.', '').replace(',', '.')) /
        100
      )
    } else {
      return parseFloat(
        str.replace(` ${unit}`, '').replace('.', '').replace(',', '.')
      )
    }
  }

  // Handle input change
  const handleInputChange = value => {
    setInputValue(value)
  }

  // Handle commit of value
  const handleBlur = () => {
    const parsedValue = parseNumber(inputValue)
    changeTrigger(parsedValue)
  }

  // Increment function to increase value by steps
const handleIncrement = () => {
  const newValue = Math.min(inputValue + steps, max); // Ensure it does not exceed max
  setInputValue(newValue);
  const parsedValue = parseNumber(newValue); // Change inputValue to newValue here
  changeTrigger(parsedValue);
};


  // Decrement function to decrease value by 1
  const handleDecrement = () => {
    const newValue = Math.max(inputValue - steps, min) // Ensure it does not go below min
    setInputValue(newValue)
    const parsedValue = parseNumber(newValue) // Parse the new value
    changeTrigger(parsedValue) // Trigger the change
  }

  return (
    <div
  style={{
    marginTop: '10px',
    flex: `1 1 calc(${minWidth}px + 60px`,
    minWidth: minWidth
  }}
>
  {/* Header */}
  <div
    style={{
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '14px',
      marginBottom: '10px',
      color: colors.text
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }}
    >
      <HeaderWithInfoIcon 
        parameter={parameter}
      />
    </div>
  </div>
  {/* Input and Unit Container */}
  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderColor: colors.buttons.primary,
        borderWidth: '1px',
        borderRadius: '5px',
        borderStyle: 'solid',
        minHeight: '50px'
      }}
    >
      <div
        onClick={handleDecrement}
        style={{
          backgroundColor: colors.buttons.primary,
          color: colors.text,
          borderRadius: '3px',
          cursor: 'pointer',
          height: '100%',
          minWidth: '40px',
          minHeight: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '0 0 auto',
          userSelect: 'none'
        }}
      >
        -
      </div>
      <InputNumber
        key={parameter}
        value={inputValue}
        min={min}
        max={max}
        step={steps}
        onChange={handleInputChange}
        onBlur={handleBlur}
        style={{
          flex: '1',
          height: '100%',
        }}
        decimalSeparator=','
        pattern='[0-9]*'
        inputmode='decimal'
        formatter={formatNumber}
        parser={parseNumber}
        controls={false}
      />
      <div
        onClick={handleIncrement}
        style={{
          backgroundColor: colors.buttons.primary,
          color: colors.text,
          borderRadius: '3px',
          cursor: 'pointer',
          height: '100%',
          minWidth: '40px',
          minHeight: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: '0 0 auto',
          userSelect: 'none'
        }}
      >
        +
      </div>
    </div>
    {/* Fixed Unit on the Right */}
    <span
      style={{
        marginLeft: '10px',
        fontSize: '16px',
        color: colors.text,
        fontWeight: 'bold',
        minWidth: 40
      }}
    >
      {unit !== 'Jahr(e)' ? unit : ''}
    </span>
  </div>
</div>

  )
}

export default InputTextCalculator
