import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import Homepage from './Homepage/Homepage';
import Map from './Map';
import Calculator from './Calculator/Calculator';
import GuideOverview from './Pages/Guide/GuideOverview';
import DetailPage from './Pages/DetailPage';

// Component to handle Matomo tracking on route changes
const MatomoTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Trigger Matomo tracking on route change
    if (window._mtm) {
      window._mtm.push({
        event: 'mtm.PageView',
        url: window.location.href,
        title: document.title,
      });
    }
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    // Matomo Tag Manager Initialization
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = 'https://analytics.kaihaenig.com/js/container_BlYJEo0A.js';
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <LanguageProvider>
      <Router>
        <div className="App">
          {/* Matomo Tracker for route changes */}
          <MatomoTracker />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/map" element={<Map />} />
            <Route path="/investcalculator" element={<Calculator />} />
            <Route path="/guide" element={<GuideOverview />} />
            <Route path="/guide/:articleUrl" element={<DetailPage />} />
            <Route path="/:PageId" element={<DetailPage />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;
