import React from 'react'
import colors from '../utils/colors'
import HoverLink from './HoverLink'
import useDeviceType from '../hooks/useDeviceType'
import logoBrandText from '../Assets/ImmoSachen_Logo_text_only.png'

const Footer = () => {
  const { isMobile } = useDeviceType()
  const footerStyle = {
    display: 'flex',
    flexWrap: 'wrap', // Allow columns to wrap
    justifyContent: 'space-between',
    alignItems: 'flex-start', // Align items to the top
    padding: isMobile ? '20px' : '20px 100px',
    backgroundColor: colors.buttons.primary,
    borderTop: '1px solid #e0e0e0'
  }

  const textColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 300px', // Flex-grow and shrink with a min-width
    padding: '0 20px',
    minWidth: '150px', // Set a minimum width for the columns
    maxWidth: 300,
    marginBottom: '20px' // Add spacing between columns
  }

  return (
    <div style={footerStyle}>
      {/* First Text Column */}
      <div style={textColumnStyle}>
        <h4>Produkte</h4>
        <HoverLink to='/map'>ImmoExplorer</HoverLink>
        <HoverLink to='/investcalculator'>ImmoInvest</HoverLink>
      </div>

      {/* Second Text Column */}
      <div style={textColumnStyle}>
        <h4>Über Uns</h4>
        <HoverLink to='/team'>Das Team</HoverLink>
        <span>Email: kontakt@longminds.de</span>
        <span>Phone: +49 1234 32121242</span>
      </div>

      {/* Third Text Column */}
      <div style={textColumnStyle}>
        <h4>Legal</h4>
        <HoverLink to='/impressum'>Impressum</HoverLink>
        <HoverLink to='/datenschutz'>Datenschutz</HoverLink>
        <HoverLink to='/agb'>AGB</HoverLink>
      </div>

      {/* Logo Column */}
      <div style={{ margin: 'auto 0', display: 'flex', padding: '0 20px' }}>
        <img
          src={logoBrandText} // Using the variable for the image source
          alt='ImmoSachen Logo'
          style={{ height: '30px', marginRight: '8px' }}
        />
      </div>
    </div>
  )
}

export default Footer
