import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Navbar, Container, Nav, NavDropdown, Card } from 'react-bootstrap'
import useDeviceType from '../hooks/useDeviceType'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Homepage.css'
import { useNavigate } from 'react-router-dom'
import Footer from './Footer'
import KeyFactsBar from './KeyFactsBar'
import colors from '../utils/colors'
import config from '../utils/config'
import FeatureBox from './FeatureBox'
import { translate } from '../utils/translationManager'
import { useLanguage } from '../context/LanguageContext'

import backgroundImage from '../Assets/picture_building_b_w_half_green_marker.png'
import buildingTeaserMap from '../Assets/building_teaser_map.png'
import buildingTeaserCalculator from '../Assets/picture_teaser_calculator.png'
import gifTeaserCalculator from '../Assets/teaser_frame_laptop_calculator.gif'
import gifTeaserMap from '../Assets/teaser_frame_laptop_map.gif'
import logoBrandText from '../Assets/ImmoSachen_Logo_text_only.png'
import HeaderNavigation from '../components/HeaderNavigation'

const HomePage = () => {
  const { isMobile } = useDeviceType()
  const navigate = useNavigate()
  const { language } = useLanguage()

  const heroStyle = {
    background: `
    linear-gradient(135deg, rgba(128, 128, 128, 0.6), rgba(128, 128, 128, 0.6)),
    url(${backgroundImage})
  `,
    backgroundSize: '100%', // Ensures the image covers the entire area
    backgroundPosition: 'center 15%', // Center the background image
    color: '#fff',
    padding: isMobile ? '50px 20px' : '100px 50px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    minHeight: isMobile ? 'none' : '400px'
  }

  const sectionStyle = {
    margin: '0 auto',
    padding: isMobile ? '20px' : '50px 20px',
    maxWidth: '1200px',
    boxSizing: 'border-box'
  }

  const rowStyleTeaser = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '30px',
    flexWrap: 'wrap' // Ensures proper breaking on smaller screens
  }

  const buttonStyle = {
    backgroundColor: colors.buttons.primary,
    color: colors.text,
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // button shadow
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  }

  const cardStyle = {
    flex: '1 1 300px',
    maxWidth: '95%',
    minWidth: 200,
    margin: '0 10px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
    cursor: 'pointer'
  }

  const handleHover = e => {
    e.currentTarget.style.transition = 'transform 0.5s ease'
    e.currentTarget.style.transform = 'scale(1.05)'
  }

  const handleMouseLeave = e => {
    e.currentTarget.style.transform = 'scale(1)'
  }

  const handleClick = link => {
    navigate(link) // Navigate to the given link
  }

  const handleMailTo = () => {
    window.location.href = 'mailto:contact@longminds.de?subject=Kontaktaufnahme' // Replace with your email
  }

  return (
    <div>
      {/* Navbar */}
      <HeaderNavigation />

      {/* Hero Section */}
      <div style={heroStyle}>
        <h1>Deine Immobilie. Dein Investment. Deine Entscheidung.</h1>
        <p>
          Immobilienbewertung unter berücksichtigung der Lage, Umgebung,
          Investitionspotenzial, Marktwert und Betrieb.
        </p>
        <button
          style={buttonStyle}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(config.apps.immoMap)}
        >
          Jetzt starten <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      {/* Apps Section */}
      <div style={sectionStyle}>
        <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>
          Unser Produktportfolio
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', // Center the cards
            flexWrap: 'wrap', // Allow wrapping for responsiveness
            gap: '20px' // Add space between cards
          }}
        >
          <Card
            style={cardStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(config.apps.immoMap)}
          >
            <Card.Img
              variant='top'
              src={buildingTeaserMap}
              style={{ minHeight: 50, maxHeight: 400 }}
            />
            <Card.Body className='navbar_add_shadow'>
              <Card.Title>{translate('immoMap', language)}</Card.Title>
              <Card.Text>
                Die interaktive Karte, die Ihnen ermöglicht, Immobilienstandorte
                zu entdecken, Bodenrichtwerte anzuzeigen und wichtige
                Marktinformationen auf einen Blick zu erhalten.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            style={cardStyle}
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(config.apps.immoCalculator)}
          >
            <Card.Img
              variant='top'
              src={buildingTeaserCalculator}
              style={{ minHeight: 50, maxHeight: 400 }}
            />
            <Card.Body>
              <Card.Title>{translate('immoCalculator', language)}</Card.Title>
              <Card.Text>
                Der leistungsstarke Renditerechner, der Ihnen hilft, die
                potenziellen Erträge Ihrer Immobilieninvestitionen präzise zu
                berechnen und fundierte Entscheidungen zu treffen.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* Features Section */}
      <div style={sectionStyle}>
        <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>
          Use-Cases inkl. Bilder
        </h2>

        {/* First Row: Image left, Text right */}
        <div style={rowStyleTeaser}>
          <img
            src={gifTeaserMap}
            alt='GIF teaser calculator'
            style={{ flex: '1', maxWidth: '100%', height: 'auto' }}
          />
          <div
            style={{
              flex: '1',
              padding: '10px'
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold' }}>
                Entdecken Sie unsere innovative Gebäudekarte
              </span>
              , die Ihnen nicht nur Gebäude, Grundstücksgrenzen und Flurstücke
              präsentiert, sondern auch eine Vielzahl umfangreicher Parameter zu
              den angezeigten Elementen bietet.
              <br /> So haben Sie alle wichtigen Daten auf einen Blick. Eine{' '}
              <span style={{ fontWeight: 'bold' }}>
                detaillierte Lagebewertung
              </span>
              , die die Abstände zu relevanten Orten wie Ärzten und Schulen
              anzeigt, sowie negative Faktoren wie Hauptstraßen oder Bahnlinien
              berücksichtigt.
              <br />
              Darüber hinaus berechnet unser fortschrittlicher Algorithmus das{' '}
              <span style={{ fontWeight: 'bold' }}>
                Investitionspotenzial
              </span>{' '}
              für jedes Gebäude, sodass Sie fundierte Entscheidungen treffen
              können.
            </div>
          </div>
        </div>

        {/* Second Row: Text left, Image right */}
        <div style={{ ...rowStyleTeaser, marginTop: 50 }}>
          <div
            style={{
              flex: '1',
              padding: '10px'
            }}
          >
            <div>
              <span style={{ fontWeight: 'bold' }}>
                Nutzen Sie unseren Investitionsrechner
              </span>
              , der Ihnen die Eingabe von Kosten und Rahmenparametern wie
              Wohnfläche, Grundstücksgröße, Nebenkosten, Zinssätzen für
              Darlehen, Ihren Grenzsteuersätzen und viele weitere Optionen
              ermöglicht.
              <br />
              Erhalten Sie eine{' '}
              <span style={{ fontWeight: 'bold' }}>
                umfassende Cashflow-Analyse
              </span>
              , die Einnahmen, Renditen sowie Kauf- und Nebenkosten detailliert
              aufschlüsselt.
              <br />
              Unsere klaren Grafiken bieten Ihnen jederzeit einen vollständigen
              Überblick über Betriebs- und Leerstandskosten, sodass Sie
              informierte Entscheidungen treffen können.
            </div>
          </div>
          <img
            src={gifTeaserCalculator}
            alt='GIF teaser calculator'
            style={{ flex: '1', maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </div>
      {/* Features Section */}
      <KeyFactsBar />
      {/* Features Section */}
      <div style={sectionStyle}>
        <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>
          Wen unterstützen wir?
        </h2>
        <div
          style={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'space-between',
            flexWrap: 'wrap',
            gap: '20px'
          }}
        >
          <FeatureBox
            header='Privatpersonen'
            bullets={[
              'Wertbetrachtung der eigenen Immobilie',
              'Kaufen vs. Mieten - Rechner',
              'Investitionsrechner'
            ]}
          />
          <FeatureBox
            header='Gewerbliche und private Investoren'
            bullets={[
              'Bewertung von Immobilien',
              'Alle Informationen in einem Portal auf einen Blick',
              'Bewertung der Immobilie nach Potential, Lage, Nachhaltigkeit und Lebensqualität '
            ]}
          />
          <FeatureBox
            header='Planer und Projektentwickler'
            bullets={[
              'Immobilienbewertung',
              'Analyse von Immobilienstandorten',
              'Renditeoptimierung'
            ]}
          />
        </div>
      </div>

      {/* CTA Section */}
      <div
        style={{
          backgroundColor: colors.backgroundSecondary,
          textAlign: 'center',
          padding: '50px 20px',
          width: '100%',
          maxWidth: 'none'
        }}
      >
        <h2>Bereit, loszulegen oder hast du noch Fragen?</h2>
        <div style={{ margin: 20 }}>
          Schreib' uns – wir sind nur einen Klick entfernt!
        </div>
        <button
          style={buttonStyle}
          onClick={handleMailTo}
          onMouseEnter={handleHover}
          onMouseLeave={handleMouseLeave}
        >
          Lass' uns reden <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
