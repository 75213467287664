import enTranslations from '../languages/en'
import deTranslations from '../languages/de'

const translations = {
  en: enTranslations,
  de: deTranslations
}

// Utility function to get translations for a specific key and language
export const translate = (key, language = 'de') => {
  const lang = translations[language] || translations.en
  return lang[key] || key // Return the translation or the key if not found
}
