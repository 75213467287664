const colors = {
  primary: '#007aff', // Primary color blue
  secondary: '#34c759', // Secondary color
  background: '#f2f2f7',
  backgroundContainer: '#fff', // Background color
  backgroundSecondary: '#f4f4f4',
  //IMPORTANT: Background color that fits the buttons primary: #E0E0E0
  text: '#333333', // Text color
  text1: '#666', //TODO Change to this color Backup Text color nice
  border: '#A8D5BA',
  icon: '#5e666f', // Border color
  iconSecondary: '#28a745',
  buttons: {
    primary: '#A8D5BA',
    secondary: '#D3D3D3'
  },
  linkHover: '',
  link: '#53946d',
  hover: '#A8D5BA',
  inactive: '#D3D3D3', // Gray
  active: '#4db6ac',
  backgroundSubHeader: '#F3E5F5',

  alphaColor: '#ff5733',
  betaColor: '#f9a825',

  statusbar: {
    inactive: '#D3D3D3', // Gray
    cat1: '#FFCC99', // Peachy Orange
    cat2: '#D9B9D7', // Lavender
    cat3: '#FFB299', // Coral
    cat4: '#A4D3A2', // Mint Green
    cat5: '#FFA07A', // Salmon
    cat6: '#FFD700', // Golden Yellow
    cat7: '#B0E0E6', // Powder Blue
    cat8: '#FF69B4', // Hot Pink
    cat9: '#98FB98', // Pale Green
    cat10: '#FFB6C1' // Light Pink
  },

  buildingColors: {
    cat1: '#EED7B9', // TBD orange
    cat2: '#CEE1CE', // TBD green
    cat3: '#E5D8E0', // TBD purple
    cat4: '#EED4C7', // TBD reddish
    cat5: '#B2D8E6', // light blue
    cat6: '#D4C5E6', // lavender
    cat7: '#F6EAC2', // pale yellow
    cat8: '#B9E1E6', // aqua
    cat9: '#F6B8A9', // coral
    cat10: '#D6E5C5', // pale green
    cat11: '#E6C3D8', // soft pink
    cat12: '#C2E6D2', // mint green
    cat13: '#F7E4A9', // light golden yellow
    cat14: '#C5D8F6' // pale sky blue
  },

  categoryColors: {
    Category1: '#FF0000', // Red
    Category2: '#00FF00', // Green
    Category3: '#0000FF', // Blue
    Category4: '#FFFF00', // Yellow
    Category5: '#FF00FF' // Magenta
  },

  chartColors: {
    cat1: '#EED7B9', // TBD orange
    cat2: '#CEE1CE', // TBD green
    cat3: '#E5D8E0', // TBD purple
    cat4: '#EED4C7', // TBD reddish
    cat5: '#B2D8E6', // light blue
    cat6: '#D4C5E6', // lavender
    cat7: '#F6EAC2', // pale yellow
    cat8: '#B9E1E6', // aqua
  },

  success: '#28a745',
  warning: '#ffc107',
  error: '#dc3545',

  // Add your colorMapping here
  colorMappingBuildingType: {
    public: '#FFA500', // Brighter Orange
    miscellaneous: '#66C266', // Stronger Green
    residential: '#C473B2', // Vibrant Purple
    garage: '#FF6347', // Tomato Red
    industry: '#5CACEE', // Stronger Light Blue
    business: '#9370DB', // Deeper Lavender
    parking: '#FFD700', // Bright Yellow
    leisure: '#00CED1' // Stronger Aqua
  },
  calculator: {
    low: '#FF6666',
    medium: '#FFCC66',
    high:'#AAFF66'
  }
}

export default colors
