import maplibregl from 'maplibre-gl'

const mapClickHandler = (
  e,
  mapInstance,
  markerRef,
  searchMarker,
  setOverlayData,
  setOverlayParcelsData,
  setShowOverlay,
  layerIds
) => {
  // Filter out non-existing layers
  const existingLayers = layerIds.filter((layerId) => mapInstance.getLayer(layerId));

  if (existingLayers.length === 0) {
    console.warn("No valid layers exist for querying.");
    return;
  }

  // Query the features at the clicked point from the existing layers
  const features = mapInstance?.queryRenderedFeatures(e.point, {
    layers: existingLayers,
  });

  // Marker handling
  const { lngLat } = e
  const { lng, lat } = lngLat

  //reset Search Marker onclick
  if (searchMarker.current) {
    searchMarker.current.remove()
    searchMarker.current = null
  }

  // If no data was hit, remove marker
  if (features?.length === 0) {
    if (markerRef.current) {
      // Remove the marker from the map
      markerRef.current.remove()
      markerRef.current = null // Clear the reference
    }
  } else {
    // Update Marker if exists and data
    if (markerRef.current) {
      markerRef.current.setLngLat([lng, lat])
    } else {
      // Create new Marker
      const newMarker = new maplibregl.Marker()
        .setLngLat([lng, lat])
        .addTo(mapInstance)

      markerRef.current = newMarker
    }
  }

  if (features?.length > 0) {
    // Extract the necessary properties
    console.log("featuresALL", features)
    let buildingObject = null;
    let landParcelsObject = null;

    for (const item of features) {
      if (!buildingObject && item.source === "immo_layer") {
        buildingObject = item; // Store the first "immo_layer" source object
      }
      
      if (!landParcelsObject && item.source === "flurstuecke") {
        landParcelsObject = item; // Store the first "flurstuecke" source object
      }
    
      // If both objects are found, exit the loop early
      if (buildingObject && landParcelsObject) {
        break;
      }
    }

    // Update the overlayData state with the extracted properties
    setOverlayData(buildingObject)
    console.log(buildingObject)
    setOverlayParcelsData(landParcelsObject)
    console.log(landParcelsObject)
    // Show the overlay
    setShowOverlay(true)
  } else {
    // If no features were clicked, hide the overlay
    setOverlayData({})
    setShowOverlay(false)
  }
}

export default mapClickHandler
