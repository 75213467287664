export const getUserLocation = () => {
  return new Promise(resolve => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          resolve({ latitude, longitude })
        },
        () => {
          resolve(null) // Return null if geolocation fails
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      )
    } else {
      resolve(null) // Return null if geolocation is not supported
    }
  })
}
