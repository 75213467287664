import React from 'react'
import colors from '../../utils/colors' // Make sure this has the color definitions you need
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'
import './AppElement.css'

const AppElement = ({ appName, link, logo }) => {
    
    const navigate = useNavigate();
    const handleLinkClick = () => {
        navigate(link); // Navigate to the link when clicked
      };

  return (
    <div
    onClick={handleLinkClick}
      style={{
        '--hover-color': colors.hover,
        display: 'flex',
        alignItems: 'center',
        padding: '0 16px',
        height: 50,
        minWidth: 220,
        borderRadius: 8,
        boxShadow: `0 2px 4px rgba(0, 0, 0, 0.2)`,
        transition: 'background-color 0.3s ease, transform 0.3s ease',
        cursor: 'pointer',
        marginBottom: 8, // Adjust spacing between items if needed
        textDecoration: 'none', // Remove underline from links
        fontSize: 28
      }}
      className='app-navbar-item'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          width: 50
        }}
      >
        <FontAwesomeIcon icon={logo} />
      </div>
      <span
        style={{
          fontSize: 16,
          fontWeight: 500,
          color: colors.text, // Adjust based on your theme
          marginLeft: 15
        }}
      >
        {appName}
      </span>
    </div>
  )
}

export default AppElement
