import React from 'react';
import colors from '../../utils/colors';
import OutputCalculatorSideInfo from './OutputCalculatorSideInfo'
import OutputCalculatorSideChart from './OutputCalculatorSideChart';
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'


const OutputCalculatorSide = ({ header, calculatedValues }) => {
  const { language } = useLanguage()
  
  console.log('Data sent to Sidepanel ', calculatedValues)

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        flex: '1 1 400px',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {header && (
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              marginBottom: '20px',
              color: colors.text,
            }}
          >
            {translate(header, language)}
            <OutputCalculatorSideInfo data={calculatedValues} keys={['yearlyRent', 'effectiveRent', 'grossRentalYield', 'netRentalYield', 'cocReturn10', 'netOperatingIncome','avgNetCashflow10', 'preTaxProfit10']} header={'incomeAndYield'}/>
            <OutputCalculatorSideInfo data={calculatedValues} keys={['immoPrice', 'costRealestateAgent', 'costNotary', 'costLandRegister', 'costCountyTaxes']} header={'purchaseAndRelatedCosts'}/>
            <OutputCalculatorSideInfo data={calculatedValues} keys={['loanAmount', 'ltvRatio', 'equityPercentage', 'annuity', 'creditDuration' ]} header={'financing'}/>
            <OutputCalculatorSideInfo data={calculatedValues} keys={['costPerSquareMeter', 'operatingCosts', 'allocableOperatingCosts', 'nonAllocableOperatingCosts', 'vacancy']} header={'operatingAndVacancyCosts'}/>
            <OutputCalculatorSideInfo data={calculatedValues} keys={['landValue', 'depreciationStartValue', 'depreciationRate', 'depreciationDegressiveEligible', 'marginTaxRate', 'taxes10', 'repaymentCoverageRatio10']} header={'taxationInformation'}/>
            <OutputCalculatorSideChart data={calculatedValues} keys={['landValue', 'depreciationStartValue']} header={'Cashflow'}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutputCalculatorSide;
