import React from 'react';
import { useParams } from 'react-router-dom';
import articles from './Guide/guideContent';
import { Impressum, DataPrivacy } from './generalContent';
import { translate } from '../utils/translationManager';
import HeaderNavigation from '../components/HeaderNavigation';

const DetailPage = () => {
  const { PageId, articleUrl } = useParams(); 

  let article;
  if (PageId === Impressum.url) {
    article = Impressum;
  } else if (PageId === DataPrivacy.url) {
    article = DataPrivacy;
  } else {
    article = articles.find(item => item.url === articleUrl);
  }

  if (!article) {
    return <div style={{ padding: '20px', textAlign: 'center', color: '#555' }}>Article not found</div>;
  }

  return (
    <div style={{ background: '#f3f4f6', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      {/* Navbar */}
      <HeaderNavigation />

      {/* Article Content */}
      <div style={{ padding: '40px', maxWidth: '1200px', margin: '20px auto', background: '#fff', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <h1 style={{ fontSize: '2.2rem', fontWeight: '600', color: '#333', marginBottom: '20px', textAlign: 'left' }}>
          {translate(article.header, article.language)}
        </h1>

        {article.imageLink && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <img
              src={article.imageLink}
              alt={article.header}
              style={{ width: '100%', maxWidth: '600px', height: 'auto', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}
            />
          </div>
        )}

        <div style={{ lineHeight: '1.6', color: '#444' }}>
          {article.content && article.content.map((item, index) => {
            switch (item.type) {
              case 'heading':
                return React.createElement(
                  `h${item.level}`, 
                  { key: index, style: { fontSize: `${1.6 - 0.1 * item.level}rem`, fontWeight: '500', color: '#555', margin: '24px 0 12px' } }, 
                  item.text
                );
              case 'paragraph':
                return <p key={index} style={{ margin: '12px 0', fontSize: '1rem', color: '#666' }}>{item.text}</p>;
              case 'image':
                return (
                  <div key={index} style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      style={{ width: '100%', maxWidth: '600px', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}
                    />
                  </div>
                );
              case 'list':
                return (
                  <ul key={index} style={{ paddingLeft: '20px', margin: '12px 0', listStyleType: 'disc', color: '#666' }}>
                    {item.items.map((listItem, listIndex) => (
                      <li key={listIndex} style={{ margin: '8px 0' }}>{listItem}</li>
                    ))}
                  </ul>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
