import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useDeviceType from '../hooks/useDeviceType';
import colors from '../utils/colors';

const FeatureBox = ({ header, bullets }) => {
  const { isMobile } = useDeviceType();

  // Dynamic styles based on device type
  const featureBoxStyle = {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)', // soft shadow
    padding: '20px',
    textAlign: 'left',
    flex: isMobile ? '1 0 100%' : '1 0 30%', // responsive for mobile and desktop
    marginBottom: '20px',
    transition: 'transform 0.2s ease-in-out',
  };

  const listStyle = {
    listStyleType: 'none',
    paddingLeft: '0',
    marginTop: '10px',
  };

  const listItemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    color: colors.text,
  };

  const checkIconStyle = {
    marginRight: '10px',
    color: colors.iconSecondary, // Green checkmark
  };

  return (
    <div style={featureBoxStyle}>
      <h3 style= {{textAlign: 'center', minHeight: 70}}>{header}</h3>
      <ul style={listStyle}>
        {bullets.map((bullet, index) => (
          <li key={index} style={listItemStyle}>
            <FontAwesomeIcon icon={faCheck} style={checkIconStyle} />
            {bullet}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FeatureBox;
