import colors from '../../utils/colors'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

const OutputCalculatorSideInfo = ({ data, keys, header }) => {
  const { language } = useLanguage()

  // Check if keys is null, undefined, or an empty array
  const shouldShowAll = !keys || !Array.isArray(keys) || keys.length === 0

  // If `data` is null or undefined, return early or show a fallback UI
  if (!data) {
    return (
      <div
        style={{
          padding: '20px',
          backgroundColor: colors.backgroundContainer,
          borderRadius: '15px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          margin: '30px auto 0',
          fontFamily: 'Arial, sans-serif',
          textAlign: 'center'
        }}
      >
        <span>{translate('No data available', language)}</span>
      </div>
    )
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        margin: '30px auto 0',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      {/* Render the header if provided */}
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {translate(header, language)}
        </div>
      )}

      {(shouldShowAll
        ? Object.entries(data)
        : keys.map(key => [key, data[key]])
      ).map(([key, entry], index) => {
        // Check if `entry` exists and is defined
        if (!entry || typeof entry !== 'object') {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                fontWeight: 'bold',
                fontSize: '16px',
                justifyContent: 'space-between',
                padding: '5px 0'
              }}
            >
              <span>{translate(key, language)}</span>
              <span>N/A</span> {/* Display 'N/A' if entry is undefined */}
            </div>
          )
        }

        // Destructure the entry object to extract value, unit, and steps
        const { value, unit } = entry || {}

        // Check if `value` and `unit` are defined
        if (value === undefined || unit === undefined) {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                fontWeight: 'bold',
                fontSize: '16px',
                justifyContent: 'space-between',
                padding: '5px 0'
              }}
            >
              <span>{translate(key, language)}</span>
              <span>N/A</span>{' '}
              {/* Display 'N/A' if value or unit is undefined */}
            </div>
          )
        }

        return (
          <div
            key={index}
            style={{
              display: 'flex',
              fontWeight: 'bold',
              alignItems: 'center',
              fontSize: '16px',
              justifyContent: 'space-between',
              padding: '5px 0',
            }}
          >
            {/* Display the translated key */}
            <span style={{
    display: 'block', // Make it a block-level element
    width: 'calc(100% - 95px)', // Calculate width minus margin/padding
    overflowWrap: 'break-word', // Ensure long words break
    overflow: 'hidden', // Hide overflow
    whiteSpace: 'normal', // Allow wrapping to the next line
  }}>{translate(key, language)}</span>

            {/* Display the formatted value and unit, with fallback to 'N/A' */}
            <span
              style={{
                minWidth: '50px',
                display: 'inline-block', // Makes the span behave like a block element
                textAlign: 'right', // Aligns the text to the right
                color: colors.text // Optional: set the text color
              }}
            >
              {unit === 'boolean'
                ? value === 1
                  ? translate('yes', language)
                  : translate('no', language)
                : unit === '%'
                ? (value * 100).toLocaleString('de-DE', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2
                  }) + ' %'
                : value.toLocaleString('de-DE', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }) +
                  ' ' +
                  unit}
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default OutputCalculatorSideInfo
