import { translate } from "../translationManager";

const generateMultiSelectOptions = (category, language) => {
  // Return an array of options for the given key
  // generate key and value Object incl. Translation
  return Object.keys(category).map(key => ({
    label: translate(key, language),
    value: key
  }));
}

export default generateMultiSelectOptions;
