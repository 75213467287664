import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes
import colors from '../utils/colors' // Import your color scheme

const KPIBar = ({
  barLabel,
  value,
  barColors,
  marginTop,
  min = 0,
  max = 1,
  boundaryTop,
  boundaryBottom,
  showLabel = true
}) => {
  // Calculate fill percentage based on min and max values
  const fillPercentage = ((value - min) / (max - min)) * 100

  // Setup colors from array, if array
  const barColor1 = Array.isArray(barColors) ? barColors[0] : barColors
  const barColor2 = Array.isArray(barColors) ? barColors[1] : barColors
  const barColor3 = Array.isArray(barColors) ? barColors[2] : barColors

  return (
    <div style={{ marginTop: marginTop || '20px', width: '100%' }}>
      {/* Label and Value Display */}
      {showLabel && (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          fontSize: '16px',
          color: colors.text, // Adjust text color
          fontFamily: 'Arial, sans-serif'
        }}
      >
        <span>{barLabel}</span>
        <span>
          {(value * 100).toLocaleString('de-DE', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          })}{' '}
          %
        </span>
        {/* Display percentage */}
      </div>
      )}

      {/* Progress Bar Container */}
      <div
        style={{
          marginTop: '10px',
          height: '25px',
          width: '100%',
          backgroundColor: colors.statusbar.inactive, // Inactive bar background
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}
      >
        {/* Filled Part of the Progress Bar */}
        <div
          style={{
            height: '100%',
            width: `${fillPercentage}%`,
            background: `${
              fillPercentage <= boundaryBottom
                ? barColor1 // Pastel red
                : fillPercentage <= boundaryTop
                ? barColor2 // Pastel yellow
                : barColor3 // Pastel green
            }`,
            transition: 'width 0.6s ease, background-color 0.6s ease', // Smooth transition for width and background color
            boxShadow: `0px 0px 8px ${
              fillPercentage <= boundaryBottom
                ? barColor1 // Pastel red
                : fillPercentage <= boundaryTop //for Calculator
                ? barColor2 // Pastel yellow
                : barColor3 // Pastel green
            }`,
            borderRadius: '20px'
          }}
        />
      </div>
    </div>
  )
}

// Define prop types for KPIBar component
KPIBar.propTypes = {
  barLabel: PropTypes.string.isRequired, // Label for the bar
  value: PropTypes.number.isRequired, // Value to calculate the fill percentage from
  barColors: PropTypes.oneOfType([
    PropTypes.string, // Single color
    PropTypes.arrayOf(PropTypes.string) // Array of colors
  ]).isRequired,
  marginTop: PropTypes.string, // Optional: margin at the top
  min: PropTypes.number, // Minimum value for the bar
  max: PropTypes.number // Maximum value for the bar
}

export default KPIBar
