import React, { useState } from 'react'
import colors from '../../utils/colors'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

const InfoListOverlay = ({ overlayData, values, header, collapse }) => {
  const { language } = useLanguage()

  // State to track if the list is collapsed or expanded
  const [isCollapsed, setIsCollapsed] = useState(true)

  // Function to toggle between collapsed and expanded state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  function formatValue (value, steps) {
    if (value == null) return 'N/A' // Handle null or undefined

    const numericValue = Number(value)
    if (isNaN(numericValue)) {
      return translate(value, language) // Return as-is if not a number
    }

    if (steps) {
      const decimalPlaces = Math.log10(1 / steps)
      value = numericValue.toFixed(decimalPlaces)
    } else {
      value = numericValue // Use numeric value directly if no steps
    }

    value = Number(value)
    const formattedValue = value.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })

    return formattedValue
  }

  // Calculate the number of visible items based on the collapse prop and the current state
  const visibleValues = isCollapsed ? values.slice(0, collapse) : values

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        margin: '0px auto 30px',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {header}
        </div>
      )}

      {/* Render the values, but only show the number of items based on the collapse state */}
      {values &&
        Array.isArray(visibleValues) &&
        visibleValues.map(({ value, unit, steps }, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              fontWeight: 'bold',
              fontSize: '16px',
              justifyContent: 'space-between',
              padding: '1px 0'
            }}
          >
            <span
              style={{
                display: 'block', // Make it a block-level element
                width: 'calc(100% - 130px)', // Calculate width minus margin/padding
                overflowWrap: 'break-word', // Ensure long words break
                overflow: 'hidden', // Hide overflow
                whiteSpace: 'normal' // Allow wrapping to the next line
              }}
            >
              {translate(value, language)}
            </span>
            <span
              style={{
                minWidth: '50px',
                maxWidth: '200px',
                display: 'inline-block', // Makes the span behave like a block element
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                textAlign: 'right', // Aligns the text to the right
                color: colors.text // Optional: set the text color
              }}
            >
              {formatValue(overlayData?.properties[value], steps) === 'N/A'
                ? 'N/A'
                : `${
                    unit === ''
                      ? translate(overlayData?.properties[value], language) // Return the raw value if unit is ''
                      : formatValue(overlayData?.properties[value], steps)
                  }${
                    unit !== '' &&
                    !isNaN(Number(overlayData?.properties[value]))
                      ? ' ' + unit
                      : ''
                  }`}
            </span>
          </div>
        ))}

      {/* Only show the toggle button if there are more items than the collapse prop */}
      {values.length > collapse && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            color: colors.link,
            fontWeight: 'bold',
            textDecoration: 'none',
            justifyContent: 'flex-start'
          }}
          onClick={toggleCollapse}
        >
          <span style={{ marginRight: '5px' }}>
            {isCollapsed
              ? translate('show_more', language)
              : translate('show_less', language)}
          </span>
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronDown : faChevronUp}
            style={{ fontSize: '12px', transition: 'transform 0.2s' }}
          />
        </div>
      )}
    </div>
  )
}

export default InfoListOverlay
