// utils/addTileLayer.js

import tileServerUtils from './tileServerUtils'; // Adjust the import path to wherever your tileServerUtils is located
import { createFilteredLayer } from './createFilteredLayer'; // Adjust import for your createFilteredLayer utility

export const addTileLayer = async (mapInstance, tileserverLayersConfig, keyPositions, is2DView, filters) => {
  try {
    // Iterate through each key position in the keyPositions array
    
      // Get source URL and tile URL from the tile server utility
      const sourceUrl = await tileServerUtils.getSourceUrl(keyPositions[0]);
      const tileUrl = await tileServerUtils.getTileUrl(keyPositions[0]);
      
      // Fetch the metadata from the source URL
      const response = await fetch(sourceUrl);
      const tileServerData = await response.json();

      // Extract the bounds from the tile server data
      const bounds = tileServerData.bounds; // [west, south, east, north]

      // Add the source to the map instance
      mapInstance.addSource(tileserverLayersConfig[keyPositions[0]].sourceId, {
        type: 'vector',
        tiles: [tileUrl],
        bounds: bounds,
        minzoom: 13,
        maxzoom: 14
      });

      for (const keyPosition of keyPositions) {
      // Add a layer for each key position
      console.log('tileserverLayersConfig: ', tileserverLayersConfig, '; tileServerData: ', tileServerData, '; filters:', filters)
      mapInstance.addLayer(
        createFilteredLayer(
          `${tileserverLayersConfig[keyPosition].sourceId}-${tileserverLayersConfig[keyPosition].layerType}`, // Layer ID
          tileserverLayersConfig[keyPosition].sourceId, // Source ID
          tileServerData.name, // Source layer name
          tileserverLayersConfig[keyPosition].layerType,
          is2DView, // 2D or 3D view flag
          filters, // Filter options
          tileserverLayersConfig[keyPosition].paint
        )
      );

      console.log(`Layer ${tileserverLayersConfig[keyPosition].sourceId} added successfully.`);
    }
  } catch (error) {
    console.error(`Error fetching tile server metadata for key position:`, error);
  }
};
