import React, { useState } from 'react';
import { translate } from '../utils/translationManager';
import { useLanguage } from '../context/LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import useDeviceType from '../hooks/useDeviceType';

const HeaderWithInfoIcon = ({ parameter }) => {
  const [showTooltip, setShowTooltip] = useState(false); // Default to false initially
  const { language } = useLanguage();
  const { isMobile } = useDeviceType()

  // Construct `infoText` dynamically
  const infoText = translate(`${parameter}Info`, language);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        position: 'relative',
      }}
    >
      {/* Main Header Text */}
      {translate(parameter, language)}

      {/* Info Icon */}
      <div
        style={{
          marginLeft: '8px',
          cursor: 'pointer',
          color: '#888',
        }}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <FontAwesomeIcon icon={faCircleInfo} />

        {/* Tooltip Overlay */}
        {showTooltip && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: '0',
              marginTop: '4px',
              padding: '8px',
              backgroundColor: '#f9f9f9',
              border: '1px solid #ddd',
              borderRadius: '4px',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              zIndex: '1',
              whiteSpace: 'normal',
              width: isMobile ? 300 : 500,
              fontSize: '12px',
              color: '#333',
            }}
          >
            {infoText}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderWithInfoIcon;
