import React, { useState, useEffect, useRef } from 'react'
import maplibregl from 'maplibre-gl'
import config from '../utils/config'
import colors from '../utils/colors'
import useDeviceType from '../hooks/useDeviceType'
import { translate } from '../utils/translationManager'
import { useLanguage } from '../context/LanguageContext'

const SearchBar = ({ map, marker }) => {
  const { language } = useLanguage()
  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const searchBarRef = useRef(null) // Ref for detecting clicks outside
  const { isMobile } = useDeviceType()

  // Fetch autocomplete suggestions
  useEffect(() => {
    if (query.length > 2 && showSuggestions) {
      // Fetch only if query is longer than 2 characters and showSuggestions is true
      const fetchSuggestions = async () => {
        const url = `${config.locationIQ.autocomplete}?key=${
          config.locationIQ.apiKey
        }&q=${encodeURIComponent(query)}&limit=5&dedupe=1&countrycodes=de`
        try {
          const response = await fetch(url)
          const data = await response.json()
          setSuggestions(data)
        } catch (error) {
          console.error('Error fetching autocomplete suggestions:', error)
        }
      }
      fetchSuggestions()
    } else {
      setSuggestions([]) // Clear suggestions if query is empty, too short, or showSuggestions is false
    }
  }, [query, showSuggestions])

  const handleSearch = async (e, suggestion = null) => {
    if (e) e.preventDefault()

    const searchQuery = suggestion ? suggestion.display_name : query
    if (!searchQuery) return

    const url = `${config.locationIQ.baseUrl}?key=${
      config.locationIQ.apiKey
    }&q=${encodeURIComponent(searchQuery)}&format=json&countrycodes=de`

    try {
      const response = await fetch(url)
      const data = await response.json()

      if (data && data.length > 0) {
        const match = data[0]
        const { lon, lat } = match

        if (lon && lat) {
          map.setCenter([lon, lat]);
          map.setZoom(17);

          if (marker.current) {
            marker.current.remove()
            marker.current = null
          }

          const newSearchMarker = new maplibregl.Marker({
            color: colors.primary
          })
            .setLngLat([lon, lat])
            .addTo(map)

          marker.current = newSearchMarker
        }
      }
    } catch (error) {
      console.error('Error during geocoding:', error)
    }
  }

  const handleSuggestionClick = suggestion => {
    const { name, house_number, postcode, city } = suggestion.address

    // Conditionally construct the formatted address by checking each part
    let formattedAddress = ''

    if (name) {
      formattedAddress += name
    }

    if (house_number) {
      formattedAddress += formattedAddress ? ` ${house_number}` : house_number
    }

    if (postcode) {
      formattedAddress += house_number ? `, ${postcode}` : ` ${postcode}`
    }

    if (city) {
      formattedAddress += postcode ? ` ${city}` : ` ${city}`
    }

    // Update the query with the formatted address if it's not empty
    if (formattedAddress.trim()) {
      setQuery(formattedAddress)
      setShowSuggestions(false) // Close the suggestions list
      handleSearch(null, suggestion) // Trigger search immediately
    }
  }

  const handleInputChange = e => {
    setQuery(e.target.value)
    setShowSuggestions(true) // Show suggestions when user types
    setHighlightedIndex(-1) // Reset highlighted index
  }

  const handleKeyDown = e => {
    if (showSuggestions) {
      if (e.key === 'ArrowDown') {
        // Move down in the suggestions list
        setHighlightedIndex(prevIndex =>
          Math.min(prevIndex + 1, suggestions.length - 1)
        )
      } else if (e.key === 'ArrowUp') {
        // Move up in the suggestions list
        setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0))
      } else if (e.key === 'Enter') {
        // Select the highlighted suggestion
        if (highlightedIndex >= 0 && highlightedIndex < suggestions.length) {
          handleSuggestionClick(suggestions[highlightedIndex])
        } else {
          handleSearch(e) // Submit the current query
          setShowSuggestions(false) // Close suggestions on search
        }
      } else if (e.key === 'Escape') {
        // Close the suggestions list
        setShowSuggestions(false)
      }
    }
  }

  // Handle clicks outside of the search bar
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchBarRef])

  return (
    <div
      style={{
        width: isMobile ? 'calc(100% - 150px)' : '400px',
        marginLeft: 10
      }}
      ref={searchBarRef} // Attach ref to the search bar container
    >
      <form onSubmit={handleSearch}>
        <input
          type='text'
          placeholder={translate('searchLabel', language)}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          style={{
            width: '100%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '10px',
            fontSize: '16px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
          }}
        />
        {showSuggestions && suggestions.length > 0 && (
          <ul
            style={{
              listStyleType: 'none',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '10px',
              backgroundColor: '#fff',
              boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
              marginTop: '4px',
              position: 'absolute',
              zIndex: 1000,
              width: '400px'
            }}
          >
            {suggestions.map((suggestion, index) => {
              const { name, house_number, postcode, city } = suggestion.address

              // Construct the address parts conditionally
              let formattedAddress = name || '' // Start with the name if it exists

              if (house_number) {
                formattedAddress += formattedAddress
                  ? ` ${house_number}`
                  : house_number // Add house_number
              }

              if (postcode) {
                formattedAddress += house_number
                  ? `, ${postcode}`
                  : ` ${postcode}` // Add a comma before postcode if house_number exists
              }

              if (city) {
                formattedAddress += postcode ? ` ${city}` : ` ${city}` // Add city
              }

              // Only render the suggestion if the formatted address is not empty
              if (formattedAddress.trim()) {
                return (
                  <li
                    key={suggestion.place_id}
                    onClick={() => handleSuggestionClick(suggestion)}
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #eee',
                      backgroundColor:
                        highlightedIndex === index ? '#e0f7fa' : '#fff' // Highlight with a slight blue
                    }}
                  >
                    {formattedAddress}
                  </li>
                )
              }

              // Return null if the address is empty, which means nothing will be rendered
              return null
            })}
          </ul>
        )}
      </form>
    </div>
  )
}

export default SearchBar
