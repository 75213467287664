import React, { useState } from 'react'
import Slider from 'rc-slider'
import { MultiSelect } from 'react-multi-select-component'
import 'rc-slider/assets/index.css'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import colors from '../../utils/colors'
import config from '../../utils/config'
import generateMultiSelectOptions from '../../utils/filters/generateMultiSelectOptions'
import '../../css/FilterSidebar.css'

const FilterSidebar = ({ onFilterChange, activeFilters, header }) => {
  const { language } = useLanguage()

  const multiSelectOptionsBuilding = generateMultiSelectOptions(config.filters.buildingTypesCategory,language)

  // Building Height Range Filter
  const [buildingHeightRange, setbuildingHeightRange] = useState(() => {
    const filterHeight = activeFilters.filterRange || []
    const measuredHeightFilter = filterHeight.find(
      filter => filter.key === config.filters.global.buildingHeight
    )
    return measuredHeightFilter ? measuredHeightFilter.value : [0, 100]
  })

  // Invest Range Filter
  const [investRange, setInvestRange] = useState(() => {
    const filterInvest = activeFilters.filterRange || []
    const investFilter = filterInvest.find(
      filter => filter.key === config.filters.global.investPotential
    )
    return investFilter ? investFilter.value : [0, 1]
  })

  // Building Type MultiSelect Filter
  const [buildingSelected, setBuildingSelected] = useState(() => {
    const filterBuilding = activeFilters.filterRange || []
    const buildingFilter = filterBuilding.find(
      filter => filter.key === config.filters.global.buildingTypes
    )
    const selectedValues = buildingFilter ? buildingFilter.value : []
    return multiSelectOptionsBuilding.filter(option =>
      selectedValues.includes(option.value)
    )
  })

  const applyFilters = () => {
    const selectedBuildings = buildingSelected.map(option => option.value)
    // construct filter
    const newFilters = [
      ...(selectedBuildings.length > 0
        ? [{ key: config.filters.global.buildingTypes, value: selectedBuildings }]
        : []),
      { key: config.filters.global.buildingHeight, value: buildingHeightRange },
      { key: config.filters.global.investPotential, value: investRange }
    ]
    if (onFilterChange) onFilterChange(newFilters)
  }

  // Reset All Filters
  const clearAllFilters = () => {
    setbuildingHeightRange([0, 100])
    setInvestRange([0, 1])
    setBuildingSelected([])
    if (onFilterChange) onFilterChange([])
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        width: '90%',
        margin: '30px auto 0',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {header}
        </div>
      )}

      <div style={{ marginTop: '30px' }}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            marginBottom: '10px',
            color: colors.headerText
          }}
        >
          {translate('measuredheight', language)}
        </div>
        <Slider
          range
          allowCross={false}
          min={0}
          max={100}
          value={buildingHeightRange}
          onChange={setbuildingHeightRange}
          style={{ width: '100%' }}
        />
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            color: colors.headerText
          }}
        >
          <span>{buildingHeightRange[0]} m</span>
          <span>{buildingHeightRange[1]} m</span>
        </div>
      </div>

      {/* Test Slider */}
      <div style={{ marginTop: '30px' }}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            marginBottom: '10px',
            color: colors.headerText
          }}
        >
          {translate('invest_potential', language)}
        </div>
        <Slider
          range
          marks={{
            0: ' ',
            0.33: ' ',
            0.66: ' ',
            1: ' '
          }}
          step={null} // Disable continuous steps, only custom steps
          min={0}
          max={1}
          value={investRange}
          onChange={setInvestRange}
          style={{ width: '100%' }}
        />
        <div
          style={{
            marginTop: '15px',
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 'bold',
            color: colors.headerText
          }}
        >
          <span>Min</span>
          <span>Max</span>
        </div>
      </div>

      <div style={{ margin: '30px 0 10px' }}>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            marginBottom: 10,
            color: colors.headerText
          }}
        >
          {translate(config.filters.global.buildingTypes, language)}
        </div>
        <MultiSelect
          options={multiSelectOptionsBuilding}
          value={buildingSelected}
          onChange={setBuildingSelected}
          labelledBy='Select Building Types'
          overrideStrings={{
            allItemsAreSelected: translate('allItemsAreSelected', language),
            clearSearch: translate('clearSearch', language),
            clearSelected: translate('clearSelected', language),
            noOptions: translate('noOptions', language),
            search: translate('search', language),
            selectAll: translate('selectAll', language),
            selectSomeItems: translate('selectSomeItems', language)
          }}
        />
      </div>

      <div style={{ display: 'flex', gap: '10px', marginTop: '40px', justifyContent: 'space-between' }}>
        <button
          onClick={applyFilters}
          style={{
            padding: '10px 20px',
            backgroundColor: colors.buttons.primary,
            color: colors.buttonText,
            borderRadius: '10px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          {translate('applyFilter', language)}
        </button>

        <button
          onClick={clearAllFilters}
          style={{
            padding: '10px 20px',
            backgroundColor: colors.buttons.secondary,
            color: colors.buttonText,
            borderRadius: '10px',
            border: 'none',
            cursor: 'pointer'
          }}
        >
          {translate('clearFilter', language)}
        </button>
      </div>
    </div>
  )
}

export default FilterSidebar
