import React from 'react'
import colors from '../../utils/colors'
import useStandardButtonStyle from '../../styles/standardButtonStyle'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

const ToolsSidebar = ({ is2DView, toggleView, header }) => {
  const standardButtonStyle = useStandardButtonStyle()
  const { language } = useLanguage()

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        width: '90%',
        margin: '10px auto 0'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            color: colors.headerText
          }}
        >
          {header}
          <div style={{ margin: '20px 18px 0 auto', display: 'flex' }}>
            {/* 2D / 3D Toggle*/}
            <div style={{ textAlign: 'center' }}>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div
      onClick={toggleView}
      style={{ ...standardButtonStyle, marginLeft: 0 }}
    >
      {is2DView ? '3D' : '2D'}
    </div>
    <div
      style={{
        fontSize: '10px',
        color: colors.icon,
        marginTop: '4px',
        width: 54,
      }}
    >
      {is2DView ? translate('switch2D', language) : translate('switch3D', language)}
    </div>
  </div>
</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ToolsSidebar
