import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import colors from '../../utils/colors'
import articles from './guideContent' // Import the articles content
import { translate } from '../../utils/translationManager' // Assuming you have a translate function
/* TODO set proper Default picture */
import buildingTeaserMap from '../../Assets/building_teaser_map.png' // Update with your image path
import useDeviceType from '../../hooks/useDeviceType'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import HeaderNavigation from '../../components/HeaderNavigation'
import Footer from '../../Homepage/Footer'

const GuideOverview = ({ language }) => {
  const { isMobile } = useDeviceType()
  const navigate = useNavigate()
  // Get unique categories
  const categories = [
    ...new Set(articles.flatMap(article => article.categories))
  ]

  // Set initial state for each category to be collapsed (true)
  const [collapsedCategories, setCollapsedCategories] = useState(
    categories.reduce((acc, category) => {
      acc[category] = true
      return acc
    }, {})
  )

  const toggleCategory = category => {
    setCollapsedCategories(prevState => ({
      ...prevState,
      [category]: !prevState[category] // Toggle the collapse state
    }))
  }

  const handleHover = () => {
    // Your hover effect logic
  }

  const handleMouseLeave = () => {
    // Your mouse leave effect logic
  }

  const handleClick = url => {
    navigate(url)
  }

  const cardStyle = {
    marginBottom: '20px',
    minHeight: 200,
    maxHeight: 400,
    minWidth: 220,
    maxWidth: 300,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', 
    borderRadius: '10px', 
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', 
    transition: 'transform 0.3s ease', 
    overflow: 'hidden', 
  };
  

  const sectionStyle = {
    padding: isMobile ? '20px' : '50px 20px',
    boxSizing: 'border-box',
    backgroundColor: colors.backgroundContainer,
    borderRadius: '15px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    maxWidth: '100%',
    margin: '0px auto 30px',
    fontFamily: 'Arial, sans-serif'
  }

  return (
    <div>
      {/* Navbar */}
      <HeaderNavigation />

      {/* Content */}
      <div style={sectionStyle}>
        <h1 style={{ maxWidth: 1200, margin: '0px auto 30px', }}>
          {translate('Guide Overview', language)}
        </h1>
        {categories.map(category => {
          const categoryArticles = articles.filter(article =>
            article.categories.includes(category)
          )
          const isCollapsed = collapsedCategories[category] ?? true // Default to true (collapsed) if undefined
          const visibleArticles = isCollapsed
            ? categoryArticles.slice(0, 3)
            : categoryArticles

          return (
            <div key={category} style={{...sectionStyle, maxWidth: 1200}}>
              <h2 style={{ margin: '0 0 20px 47px' }}>{category}</h2>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center', 
                  flexWrap: 'wrap',
                  gap: '20px' 
                }}
              >
                {visibleArticles.map(article => (
                  <Card
                    key={article.id}
                    style={cardStyle}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleClick(`/guide/${article.url}`)}
                  >
                    <Card.Img
                      variant='top'
                      src={article.imageLink || buildingTeaserMap}
                      style={{ minHeight: 100, maxHeight: 200 }}
                    />
                    <Card.Body className='navbar_add_shadow'>
                      <Card.Title>{article.header}</Card.Title>
                      <Card.Text>{article.shortText}</Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </div>
              {categoryArticles.length > 3 && (
                <div
                  style={{
                    margin: '10px 0 0 47px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    color: colors.link,
                    fontWeight: 'bold',
                    textDecoration: 'none',
                    justifyContent: 'flex-start'
                  }}
                  onClick={() => toggleCategory(category)}
                >
                  <span style={{ marginRight: 5 }}>
                    {isCollapsed
                      ? translate('show_more', language)
                      : translate('show_less', language)}
                  </span>
                  <FontAwesomeIcon
                    icon={isCollapsed ? faChevronDown : faChevronUp}
                    style={{ fontSize: '12px', transition: 'transform 0.2s' }}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

export default GuideOverview
