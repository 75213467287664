const toggleLayer = (map, layersVisibility, setLayersVisibility, layerIds) => {
  console.log('LAYERIDs in toggleLayer: ', layerIds)
  if (!map) return;

  // Iterate through each layerId in the array
  layerIds.forEach(layerId => {
    const layerExists = map.getLayer(layerId) !== undefined;

    if (layerExists) {
      const currentVisibility = map.getLayoutProperty(layerId, 'visibility');

      // Toggle the visibility state for each layer
      if (currentVisibility === 'visible') {
        map.setLayoutProperty(layerId, 'visibility', 'none');
        setLayersVisibility(prevState => ({
          ...prevState,
          [layerId]: false // Set the specific layer's visibility to false
        }));
      } else {
        map.setLayoutProperty(layerId, 'visibility', 'visible');
        setLayersVisibility(prevState => ({
          ...prevState,
          [layerId]: true // Set the specific layer's visibility to true
        }));
      }
    } else {
      console.warn(`Layer "${layerId}" does not exist.`);
    }
  });
};

export default toggleLayer;
