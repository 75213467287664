// hooks/useDeviceType.js
import { useState, useEffect } from 'react'

const useDeviceType = () => {
  const getDeviceType = () => {
    const width = window.innerWidth
    if (width <= 920) return 'mobile'
    if (width <= 1400) return 'tablet'
    return 'desktop'
  }

  const [deviceType, setDeviceType] = useState(getDeviceType())

  useEffect(() => {
    const handleResize = () => {
      setDeviceType(getDeviceType())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    isMobile: deviceType === 'mobile',
    isTablet: deviceType === 'tablet',
    isDesktop: deviceType === 'desktop'
  }
}

export default useDeviceType
