const config = {
  locationIQ: {
    apiKey: 'pk.92971cd6a0e685c7c034527fc78733cd',
    baseUrl: 'https://us1.locationiq.com/v1/search',
    autocomplete: 'https://api.locationiq.com/v1/autocomplete'
  },

  tileserver: {
    layers: [
      {
        name: 'landParcels',
        sourceId: 'flurstuecke',
        // dev only url: 'https://d19mc7w10glfni.cloudfront.net/flurstuecke.json',
        url: 'https://d3fzievv3gctap.cloudfront.net/flurstuecke.json',
        layerType: 'fill',
        paint: {             
          'fill-color': 'green',           // Line color
          'fill-opacity': 0.3
        }
      },
      {
        name: 'landParcelsLines',
        sourceId: 'flurstuecke',
        // dev only url: 'https://d19mc7w10glfni.cloudfront.net/flurstuecke.json',
        url: 'https://d3fzievv3gctap.cloudfront.net/flurstuecke.json',
        layerType: 'line',
        paint: {             
          'line-color': '#000000',           // Line color
          'line-width': 1,                   // Line width
          'line-opacity': 1                   // Line opacity
        }
      },
      {
        name: 'buildings',
        sourceId: 'immo_layer',
        // dev only url: 'https://d19mc7w10glfni.cloudfront.net/immo_layer.json',
        url: 'https://d3fzievv3gctap.cloudfront.net/immo_layer.json',
        layerType: 'fill-extrusion',
        paint: {
          'fill-extrusion-base': 0,
          'fill-extrusion-opacity': 1,
        }
      },
    ]
  },

  map: {
    // Münster Bounds
    startBounds: [
      [7.613, 51.949],
      [7.645, 51.966]
    ]
  },
  filters: {
    global: {
      buildingTypes: 'building_group_type',   //props from tileserver
      buildingHeight: 'measuredheight',       //props from tileserver
      investPotential: 'invest_potential'     //props from tileserver
    },
    buildingTypesCategory: {                  //category of props from tileserver
      public: 'public',
      miscellaneous: 'miscellaneous',
      residential: 'residential',
      garage: 'garage',
      industry: 'industry',
      business: 'business',
      parking: 'parking',
      leisure: 'leisure'
    }
  },
  apps: {
    homepage: '/',
    immoMap: '/map',
    immoCalculator: '/investcalculator'
  },
  immoCalculator: {
    filterSlider: {
      immoPrice: {
        parameter: 'immoPrice',
        min: 1,
        max: 1000000,
        default: 275000,
        unit: '€',
        steps: 1,
        color: '#FFCC99'
      },
      coldRent: {
        parameter: 'coldRent',
        min: 0,
        max: 3000,
        default: 513.23,
        unit: '€',
        steps: 0.01,
        color: '#D9B9D7'
      },
      livingArea: {
        parameter: 'livingArea',
        min: 10,
        max: 250,
        default: 60.38,
        unit: 'm²',
        steps: 0.01,
        color: '#FFB299'
      },
    },
    filterSliderOptions: {
      realestateAgentRate: {
        parameter: 'realestateAgentRate',
        min: 0,
        max: 0.07,
        default: 0.035,
        unit: '%',
        steps: 0.001,
        color: '#A4D3A2'
      },
      interestRate: {
        parameter: 'interestRate',
        min: 0.001,
        max: 0.05,
        default: 0.0388,
        unit: '%',
        steps: 0.0001,
        color: '#FFA07A'
      }
    },
      filterSliderSpecial: {
        equity: {
          parameter: 'equity',
          min: 0,
          max: 1000000,
          default: 10650,
          unit: '€',
          steps: 1,
          color: '#FFD700'
        },
        operatingCosts: {
          parameter: 'operatingCosts',
          min: 0,
          max: 500,
          default: 156.51,
          unit: '€',
          steps: 0.01,
          color: '#B0E0E6'
        },
        allocableOperatingCosts: {
          parameter: 'allocableOperatingCosts',
          min: 0,
          max: 400,
          default: 118.67,
          unit: '€',
          steps: 0.01,
          color: '#B0E0E6'
        },
        plotSize: {
          parameter: 'plotSize',
          min: 50, // minimum plot size in square meters
          max: 10000, // maximum plot size in square meters
          default: 1166, // default plot size in square meters
          unit: 'm²',
          steps: 0.01,
          color: '#F5DEB3'
        },
        landShare: {
          parameter: 'landShare',
          min: 0.001, // minimum share of land (1%)
          max: 1, // maximum share of land (100%)
          default: 0.0354, // default value (50%)
          unit: '%',
          steps: 0.0001,
          color: '#FFD700'
        },
        landValueAssessment: {
          parameter: 'landValueAssessment',
          min: 1, // minimum land value per square meter
          max: 5000, // maximum land value per square meter
          default: 400, // default land value per square meter
          unit: '€/m²',
          steps: 1,
          color: '#32CD32'
        },
        localRentValue: {
          parameter: 'localRentValue',
          min: 1, // minimum local rental value per square meter
          max: 50, // maximum local rental value per square meter
          default: 12, // default local rental value per square meter
          unit: '€/m²',
          steps: 0.01,
          color: '#FF6347'
        },
        marginTaxRate: {
          parameter: 'marginTaxRate',
          min: 0.01, // minimum marginal tax rate (1%)
          max: 0.6, // maximum marginal tax rate (60%)
          default: 0.42, // default marginal tax rate (30%)
          unit: '%',
          steps: 0.001,
          color: '#4682B4'
        },
        // monthlyPaymentRate: {
        //   parameter: 'monthlyPaymentRate',
        //   min: 50, // minimum monthly payment
        //   max: 5000, // maximum monthly payment
        //   default: 1000, // default monthly payment
        //   unit: '€',
        //   steps: 0.01,
        //   color: '#FFB6C1'
        // },
        repaymentRate: {
          parameter: 'repaymentRate',
          min: 0.01, 
          max: 0.1,
          default: 0.0132, 
          unit: '%',
          steps: 0.0001,
          color: '#FFB6C1'
        },
        yearOfConstruction: {
          parameter: 'yearOfConstruction',
          min: 1900, // minimum year of construction
          max: new Date().getFullYear(), // maximum year is the current year
          default: 2024, // default construction year
          unit: 'Jahr(e)',
          steps: 1,
          color: '#ADD8E6'
        },
        purchaseDate: {
          parameter: 'purchaseDate',
          min: new Date(1900, 0, 1),
          max: new Date(2026, 0, 1),
          default: new Date(),
          unit: 'date',
          steps: 'none', // no steps for dates
          color: '#D8BFD8'
        },
        vacancy: {
          parameter: 'vacancy',
          min: 0, // minimum vacancy (0 months)
          max: 1, // maximum vacancy (12 months)
          default: 0.0, // default vacancy (1 month)
          unit: '%',
          steps: 0.001,
          color: '#DAA520'
        },
        depreciationDegressiveEligible: {
          parameter: 'depreciationDegressiveEligible',
          default: 1,
          unit: 'boolean',
          steps: 'none',
        }
    },
    filterMulti: {
      counties: {
        parameter: 'counties',
        default: '',
        options: {
          badenWuerttemberg: 0.05,
          bayern: 0.035,
          berlin: 0.06,
          brandenburg: 0.065,
          bremen: 0.05,
          hamburg: 0.055,
          hessen: 0.06,
          mecklenburgVorpommern: 0.06,
          niedersachsen: 0.05,
          nordrheinWestfalen: 0.065,
          rheinlandPfalz: 0.05,
          saarland: 0.065,
          sachsen: 0.055,
          sachsenAnhalt: 0.05,
          schleswigHolstein: 0.065,
          thueringen: 0.065
        }
      }
    }
  }
}

export default config
