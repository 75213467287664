const deTranslations = {
  // general
  searchLabel: 'Suche...',
  label: 'Beschreibung',
  color: 'Farbe',
  // Object Selection
  invest_potential: 'Investitionspotential',
  life_quality: 'Lebensqualität',
  location: 'Lage',
  sustainability: 'Nachhaltigkeit',
  measuredheight: 'Gebäudehöhe',
  building_group_type: 'Gebäudetyp',
  // add more keys as needed
  public: 'Öffentlich',
  miscellaneous: 'Sonstiges',
  residential: 'Wohngebäude',
  garage: 'Garagen',
  industry: 'Industrie',
  business: 'Gewerbe',
  parking: 'Parkplätze',
  leisure: 'Freizeit',
  // filter
  filter: 'Filter',
  applyFilter: 'Anwenden',
  clearFilter: 'Zurücksetzen',

  // Multiselect
  allItemsAreSelected: 'Alle Einträge ausgewählt',
  clearSearch: 'Suche zurücksetzen',
  clearSelected: 'Auswahl zurücksetzen',
  noOptions: 'keine Optionen',
  search: 'Suchen',
  selectAll: 'Alle auswählen',
  selectAllFiltered: 'Alle auswählen (Filter)',
  selectSomeItems: 'Auswahl...',
  create: 'Erstellen',

  //Select
  selectOptionCounty: 'Bundesland auswählen',

  mapTools: 'Kartenwerkzeuge',
  layerSelection: 'Layerauswahl',

  de: 'Deutsch',
  en: 'Englisch',

  immoMap: 'ImmoExplorer',
  immoCalculator: 'ImmoInvest',
  homepage: 'Startseite',

  yes: 'Ja',
  no: 'Nein',

  // calculator
  // counties
  badenWuerttemberg: 'Baden-Württemberg',
  bayern: 'Bayern',
  berlin: 'Berlin',
  brandenburg: 'Brandenburg',
  bremen: 'Bremen',
  hamburg: 'Hamburg',
  hessen: 'Hessen',
  mecklenburgVorpommern: 'Mecklenburg-Vorpommern',
  niedersachsen: 'Niedersachsen',
  nordrheinWestfalen: 'Nordrhein-Westfalen',
  rheinlandPfalz: 'Rheinland-Pfalz',
  saarland: 'Saarland',
  sachsen: 'Sachsen',
  sachsenAnhalt: 'Sachsen-Anhalt',
  schleswigHolstein: 'Schleswig-Holstein',
  thueringen: 'Thüringen',

  //KPIs
  immoPrice: 'Immobilienpreis',
  coldRent: 'mtl. Kaltmiete',
  livingArea: 'Wohnfläche',
  realestateAgentRate: 'Maklergebührensatz',
  interestRate: 'Zinssatz',
  equity: 'Eigenkapital',
  operatingCosts: 'mtl. Nebenkosten',
  allocableOperatingCosts: 'mtl. umlagefähige Nebenkosten',
  counties: 'Bundesländer',
  netOperatingIncome: 'Netto-Betriebsergebnis',
  netRentalYield: 'Nettomietrendite',
  cocReturn10: 'Eigenkapitalrendite',
  plotSize: 'Grundstücksgröße',
  landShare: 'Anteil am Land',
  localRentValue: 'Ortsübliche Vergleichsmiete',
  yearOfConstruction: 'Baujahr',
  repaymentRate: 'Tilgungsrate',
  purchaseDate: 'Kaufdatum',
  landValueAssessment: 'Bodenrichtwert',
  marginTaxRate: 'Grenzsteuersatz',
  landValue: 'Bodenwert',
  depreciationStartValue: 'Abschreibungsanfangswert',
  depreciationRate: 'Abschreibungsrate',
  taxes10: 'Gesamtbesteuerung über 10 Jahren',
  repaymentCoverageRatio10: 'Tilgungsdeckungsquote über 10 Jahren',
  avgNetCashflow10: 'Ø Netto-Cashflow über 10 Jahre',
  preTaxProfit10: 'Gesamtprofit vor Steuern über 10 Jahren',
  depreciationDegressiveEligible: 'Degressive Abschreibung erlaubt?',
  annuity: 'Annuität',
  creditDuration: 'Kreditlaufzeit',
  avgInterest10: 'Ø Zinssatz über 10 Jahre',
  avgRepaymentRate10: 'Ø Tilgungsrate über 10 Jahre',
  savedTaxes: 'Steuerersparnis',
  taxes: 'Steuern',
  cashflow: 'Cashflow',
  income: 'Einkommen',
  cost: 'Kosten',

  // Headers:
  operatingAndVacancyCosts: 'Betriebs- und Leerstandskosten',
  purchaseAndRelatedCosts: 'Kauf- & Nebenkosten',
  financing: 'Finanzierung',
  incomeAndYield: 'Einnahmen und Rendite',
  taxationInformation: 'Steuerliche Information',

  yearlyRent: 'Jahresmiete',
  effectiveRent: 'Effektive Jahresmiete',
  grossRentalYield: 'Bruttomietrendite',
  loanAmount: 'Darlehensbetrag',
  ltvRatio: 'Beleihungsquote',
  equityPercentage: 'Eigenkapitalquote',
  costRealestateAgent: 'Maklergebühren',
  costNotary: 'Notarkosten',
  costLandRegister: 'Grundbuchkosten',
  costCountyTaxes: 'Grunderwerbsteuer',
  costPerSquareMeter: 'Kosten pro Quadratmeter',
  vacancy: 'Leerstand',
  nonAllocableOperatingCosts: 'mtl. nicht umlagefähige Nebenkosten',

  // Overlay
  valueAndPotential: 'Wert & Potenzial',
  marketValueAnalysis: 'Marktwertanalyse',
  buildingAndPropertyData: 'Gebäude- & Grundstücksdaten',
  accessibilityAndServices: 'Zugänglichkeit & Versorgung',
  risksAndLiabilities: 'Risiken & Belastungen',
  buildingData: 'Gebäudedaten',
  propertyData: 'Grundstücksdaten',
  unknownAddress: 'Unbekannte Adresse',
  distance_childcare: 'Kindertagesstätten',
  distance_healthcare: 'Gesundheitsversorgung',
  distance_higher_education: 'Hoch- oder Berufsschule',
  distance_park: 'Park',
  distance_public_station: 'Öffentliche Station',
  distance_railway_line: 'Eisenbahnlinie',
  distance_school: 'Schule',
  distance_supermarket: 'Supermarkt',
  distance_thoroughfare: 'Schnellstraße',
  brw_2024: 'Bodenrichtwert',
  property_value: "Immobilienwert",
  property_value_lower: "Immobilienwert Min.",
  property_value_upper: "Immobilienwert Max.",

  show_more: 'Mehr anzeigen',
  show_less: 'Weniger anzeigen',

  propertyInformation: 'Immobilieninformationen',
  rentalAndIncome: 'Miet- & Einkommensinformationen',
  financialDetails: 'Finanzielle Details',
  taxAndValuation: 'Steuer- & Bewertungsinformationen',
  geographicalInformation: 'Geografische Informationen',

  // Informational Text Elements:
  evaluationCalculatorResultsLow:
    'Die Daten deuten darauf hin, dass dieses Investment möglicherweise weniger attraktiv ist. Weitere Faktoren könnten es verbessern, aber im aktuellen Zustand spricht wenig für diese Immobilie als lohnendes Geschäft. Überprüfe die Zahlen genau oder ziehe Alternativen in Betracht.',
  evaluationCalculatorResultsMedium:
    'Eine genaue Prüfung wird empfohlen! Diese Immobilie könnte sich lohnen, aber beispielsweise die Bruttomietrendite bewegt sich im mittleren Bereich. Es lohnt sich, weitere Details wie Instandhaltungskosten, Lage oder Finanzierung genauer zu betrachten, bevor du eine endgültige Entscheidung triffst.',
  evaluationCalculatorResultsHigh:
    'Die Berechnungen zeigen, dass diese Immobilie ein starkes Investment sein könnte. Mit einer attraktiven Rendite stehen die Chancen gut, langfristig von stabilen Erträgen zu profitieren. Natürlich lohnt sich immer ein genauer Blick, aber alle Zeichen deuten auf Grün!',

  inputParameter: 'Eingabewerte',
  outputParameter: 'Berechnungsergebnisse',

  Immo_Layer: 'Gebäudedaten',
  landParcels: 'Flurstücke',

  //Flurstuecke Layer
  flurstid: 'Flurstücks-ID',
  ags: 'Amtlicher Gemeindeschlüssel',
  land: 'Bundesland-ID',
  gemarkungsnummer: 'Gemarkungsnummer',
  flur: 'Flur',
  flurschl: 'Flurstückschlüssel',
  zaehler: 'Flurstück',
  nenner: 'Nenner',
  lagebeztxt: 'Lagebezeichnung',
  tntxt: 'Nutzungsart',
  created_at: 'Erstellt am',
  flurstueck_area: 'Flurstücksfläche',

  //Homepage
  amountOfBuildings:"Anzahl der Gebäude",
  amountOfParcels:"Anzahl der Flurstücke",
  propsPerBuilding:"Anzahl an Gebäudemerkmale",
  guideOverview: 'Ratgeber Übersicht',

  //Buttons
  switch2D: "Zur 2D Ansicht",
  switch3D: "Zur 3D Ansicht",

  /// Info Texts
immoPriceInfo:
'Der Immobilienpreis ist der Gesamtbetrag, den du für die Immobilie bezahlt hast oder bezahlen möchtest. Dieser Preis bezieht sich ausschließlich auf den Kaufpreis der Immobilie ohne zusätzliche Kosten wie Maklergebühren, Notarkosten oder Steuern.',
livingAreaInfo:
'Die Wohnfläche der Immobilie in Quadratmetern umfasst alle bewohnbaren Räume, anteilig Balkone und Gärten, jedoch keine Keller oder Garagen.',
plotSizeInfo:
'Die Grundstücksgröße in Quadratmetern ist die Fläche des Grundstücks, auf dem die Immobilie steht. Es handelt sich um die Flurstücksfläche in m². Dieser Wert wird für die Berechnung der Abschreibung benötigt.',
landShareInfo:
'Der Anteil am Land gibt an, welchen Anteil des Grundstücks deine Immobilie besitzt. Gib diesen Anteil als Prozentsatz an. Diesen findest du in der Teilungsurkunde des Gebäudes.',
yearOfConstructionInfo:
'Das Baujahr des Gebäudes gibt an, wann das Gebäude ursprünglich gebaut wurde. Renovierungen oder Kernsanierungen sind hier nicht relevant.',
coldRentInfo:
'Die mtl. Kaltmiete ist der monatliche Mietpreis ohne Betriebskosten, Heizkosten oder Nebenkosten.',
operatingCostsInfo:
'Die mtl. Nebenkosten sind die Betriebskosten der Immobilie und umfassen monatliche Zusatzkosten zur Kaltmiete wie z.B. Heizung, Verwaltung, Müllabfuhr, Wasser, Abwasser und Versicherungen.',
allocableOperatingCostsInfo:
'Die mtl. umlagefähigen Nebenkosten sind der Anteil der Betriebskosten, der auf deine Immobilie entfällt, jedoch auf Mieter umgelegt werden kann. Dies beinhaltet exemplarisch die Heizungskosten, Versicherungen oder die Grundsteuer.',
localRentValueInfo:
'Die ortsübliche Vergleichsmiete ist der Mietpreis, den vergleichbare Immobilien in der gleichen Gegend erzielen. Diesen Wert kannst du im Mietpreisspiegel der Stadt oder Kommune finden.',
vacancyInfo:
'Der Leerstand gibt den Prozentsatz der Zeit an, in der die Immobilie unvermietet ist oder leer steht. Dieser Wert wird von den Einnahmen der Kaltmiete abgezogen. Ein Leerstand tritt bei Mieterwechsel oder Sanierungen auf.',
equityInfo:
'Das Eigenkapital ist der Teil des Kaufpreises, den du ohne Fremdfinanzierung selbst bezahlt hast.',
interestRateInfo:
'Der Zinssatz gibt den jährlichen Prozentsatz an, den du für das geliehene Kapital zahlen musst. Es handelt sich um den effektiven Jahreszins.',
repaymentRateInfo:
'Die Rückzahlungsrate ist der Prozentsatz des Kredits, den du regelmäßig zurückzahlst.',
realestateAgentRateInfo:
'Der Maklergebührensatz ist die Provision, die der Immobilienmakler für den Kauf der Immobilie erhält, angegeben in Prozent des Kaufpreises.',
purchaseDateInfo:
'Das Kaufdatum gibt an, wann der Kaufvertrag abgeschlossen wurde. Dieser Wert ist wichtig, um den Anteil zu berechnen, den du für das Jahr abschreiben darfst, in dem die Immobilie erworben wurde. Das Datum des notariellen Kaufvertrages ist hier ausschlaggebend.',
landValueAssessmentInfo:
'Der Bodenrichtwert gibt den Wert des Grundstücks an, basierend auf der aktuellen Bewertung oder einem Gutachten. Der Wert kann in unserem ImmoExplorer für jedes Gebäude nachgeschaut und für den Rechner übernommen werden.',
marginTaxRateInfo:
'Der Grenzsteuersatz ist der Steuersatz, der auf jeden weiteren Euro angewendet wird, den du verdienst. Dieser Steuersatz wird auf Grundlage des zu versteuernden Einkommens (zvE) berechnet, das in deiner letzten Steuererklärung angegeben ist. Den genauen Grenzsteuersatz kannst du auf der Website des Bundesministeriums für Finanzen berechnen.',
depreciationDegressiveEligibleInfo:
'Die Möglichkeit zur degressiven Abschreibung gibt an, ob die Immobilie steuerlich für eine degressive Abschreibung berücksichtigt werden kann, wodurch eine höhere Wertminderung in den ersten Jahren ermöglicht wird. Diese Möglichkeit wurde im Rahmen des Wachstumschancengesetzes geschaffen und erlaubt eine degressive Abschreibung mit einer 5% Restwertabschreibung für einen Zeitraum von bis zu 6 Jahren. Diese Regelung ist vorerst nur für eine begrenzte Zeit verfügbar.',
countiesInfo:
'Wähle das Bundesland aus, in dem deine Immobilie oder Investition liegt. Dies ist für die steuerliche Bewertung bzw. regionale Steuern wichtig.'
}

export default deTranslations
