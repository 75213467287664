import React, { useState } from 'react'
import colors from '../../utils/colors'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const GraphicsFrameOverlay = ({ overlayData, values, header, collapse }) => {
  const { language } = useLanguage()
  const valueLower = overlayData?.properties.property_value_lower;
  const valueUpper = overlayData?.properties.property_value_upper;

  // State to track if the list is collapsed or expanded
  const [isCollapsed, setIsCollapsed] = useState(true)

  // Function to toggle between collapsed and expanded state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  function formatValue (value, steps) {
    if (value == null) return 'N/A' // Handle null or undefined

    const numericValue = Number(value)
    if (isNaN(numericValue)) {
      return translate(value, language) // Return as-is if not a number
    }

    if (steps) {
      const decimalPlaces = Math.log10(1 / steps)
      value = numericValue.toFixed(decimalPlaces)
    } else {
      value = numericValue // Use numeric value directly if no steps
    }

    value = Number(value)
    const formattedValue = value.toLocaleString('de-DE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    })

    return formattedValue
  }

  // Function to round down to the nearest multiple of 500
  function roundDownToNearest500(value) {
    return Math.floor(value / 500) * 500;
  }

  // Function to round up to the nearest multiple of 500
  function roundUpToNearest500(value) {
    return Math.ceil(value / 500) * 500;
  }

  // Calculate min and max rounded to nearest 500
  const min = roundDownToNearest500(valueLower);
  const max = roundUpToNearest500(valueUpper);

  // Calculate the number of visible items based on the collapse prop and the current state
  const visibleValues = isCollapsed ? values?.slice(0, collapse) : values

  // Chart data setup
  const xAxisLabels = ['ImmoWert']

  const chartData = {
    labels: xAxisLabels,
    datasets: [
      {
        label: 'lower',
        data: [overlayData?.properties.property_value],
        backgroundColor: '#C8E6C9', // Pastel Light Grey
        base: [overlayData?.properties.property_value_lower]
      },
      {
        label: 'medium',
        data: [overlayData?.properties.property_value + 20],
        backgroundColor: '#FFADAD', // Pastel Peach (highlight)
        base: [overlayData?.properties.property_value]
      },
      {
        label: 'upper',
        data: [overlayData?.properties.property_value_upper],
        backgroundColor: '#C8E6C9', // Pastel Light Grey
        base: [overlayData?.properties.property_value]
      }
    ]
  };
  

  // Chart configuration
  const config = {
    type: 'bar',
    data: chartData,
    options: {
      layout: {
        padding: 30,
        height: 50
      },
      responsive: true,
      indexAxis: 'y',
      scales: {
        x: {
          min: min,
          beginAtZero: false,
          max: max,
          ticks: {
            color: colors.text1,
            font: {
              weight: 'bold' // Set font weight to bold
            },
            tickColor: 'red',
            // Custom tick formatting for German notation
            callback: function (value) {
              return new Intl.NumberFormat('de-DE').format(value) + ' €' // German number formatting with Euro symbol
            }
          }
        },
        y: {
          stacked: true, // Stacked on the y-axis
          display: false
        }
      },
      plugins: {
        legend: {
          display: false // Hide the default legend
        },
        title: {
          display: false
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: function (tooltipItem) {
              // Get the dataset label (property name) and the raw value
              const label = translate(tooltipItem.dataset.label, language) || '' // e.g., "avgInterest10"
              const value = tooltipItem.raw // Raw value of the data point

              // Format the value as "1.020,32 €"
              const formattedValue =
                new Intl.NumberFormat('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(value) + ' €'

              // Return the label and the formatted value in the tooltip
              return label + ': ' + formattedValue
            }
          }
        }
      }
    }
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        margin: '0px auto 30px',
        fontFamily: 'Arial, sans-serif'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {header}
        </div>
      )}

      {/* Center the Bar chart horizontally */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: 150,
          width: '100%'
        }}
      >
        <Bar {...config} />
      </div>
      {/* Render the values, but only show the number of items based on the collapse state */}
      {values &&
        Array.isArray(visibleValues) &&
        visibleValues.map(({ value, unit, steps }, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              fontWeight: 'bold',
              fontSize: '16px',
              justifyContent: 'space-between',
              padding: '1px 0'
            }}
          >
            <span
              style={{
                display: 'block', // Make it a block-level element
                width: 'calc(100% - 130px)', // Calculate width minus margin/padding
                overflowWrap: 'break-word', // Ensure long words break
                overflow: 'hidden', // Hide overflow
                whiteSpace: 'normal' // Allow wrapping to the next line
              }}
            >
              {translate(value, language)}
            </span>
            <span
              style={{
                minWidth: '50px',
                maxWidth: '200px',
                display: 'inline-block', // Makes the span behave like a block element
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                textAlign: 'right', // Aligns the text to the right
                color: colors.text // Optional: set the text color
              }}
            >
              {formatValue(overlayData?.properties[value], steps) === 'N/A'
                ? 'N/A'
                : `${
                    unit === ''
                      ? translate(overlayData?.properties[value], language) // Return the raw value if unit is ''
                      : formatValue(overlayData?.properties[value], steps)
                  }${
                    unit !== '' &&
                    !isNaN(Number(overlayData?.properties[value]))
                      ? ' ' + unit
                      : ''
                  }`}
            </span>
          </div>
        ))}
    </div>
  )
}

export default GraphicsFrameOverlay
