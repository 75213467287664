import React, { useState } from 'react';
import Select from 'react-select'; // Using react-select for single selection
import colors from '../../utils/colors';
import generateMultiSelectOptions from '../../utils/filters/generateMultiSelectOptions';
import { translate } from '../../utils/translationManager';
import { useLanguage } from '../../context/LanguageContext';
import HeaderWithInfoIcon from '../../components/HeaderWithInfoIcon';

const InputSelect = ({ parameter, options, setValues }) => {
  const { language } = useLanguage();
  // generate key and value Object incl. Translation
  const selectOptions = generateMultiSelectOptions(options, language);

  const [selectedValue, setSelectedValue] = useState(null);

  const changeTrigger = (selected) => {
    setSelectedValue(selected);
    setValues(parameter, selected); // Pass the selected object
  };

  return (
    <div style={{ marginTop: '10px', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          fontSize: '16px',
          marginBottom: '10px',
          color: colors.text
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <HeaderWithInfoIcon 
        parameter={parameter}
      />
        </div>
      </div>
      <Select
        options={selectOptions}
        value={selectedValue}
        onChange={changeTrigger}
        placeholder={translate('selectOptionCounty', language)}
        styles={{
          control: (base) => ({
            ...base,
            borderColor: colors.active,
          }),
        }}
        menuPlacement="top"
      />
      <div
        style={{
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: 'bold',
          color: colors.text,
        }}
      >
        {/* Additional content can be added here if needed */}
      </div>
    </div>
  );
};

export default InputSelect;
