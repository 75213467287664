export const removeFilterValue = (
  filterRange,
  filterKey,
  valueToRemove,
  defaultMultiSelect
) => {
  // Check if filterRange is empty
  if (filterRange.length === 0) {
    console.log('Filter range is empty. Adding all values except the one to remove.')

    return [
      {
        key: filterKey,
        value: defaultMultiSelect.filter(value => value !== valueToRemove)
      }
    ]
  }

  // Find the filter with the given key
  const filterIndex = filterRange.findIndex(filter => filter.key === filterKey)
  // check if filterKey exists in filterRange, if not, add all other filters, except the specific one
  if (filterIndex === -1) {
    console.log('Filter key not found')
    return [
      {
        key: filterKey,
        value: defaultMultiSelect.filter(value => value !== valueToRemove)
      }
    ]
  }

  // Check if the value exists in the array
  const filter = filterRange[filterIndex]
  if (!filter.value.includes(valueToRemove)) {
    console.log('Value to remove not found in the filter')
    return filterRange // Return the original object if the value is not found
  }

  // Remove the value from the array
  const updatedValues = filter.value.filter(value => value !== valueToRemove)

  // Create a new filterRange array with the updated filter values
  const updatedFilterRange = [
    ...filterRange.slice(0, filterIndex),
    { ...filter, value: updatedValues },
    ...filterRange.slice(filterIndex + 1)
  ]

  return updatedFilterRange
}
