export const Impressum = {
  id: 1,
  sid: 'imprint',
  url: 'impressum',
  header: 'Impressum',
  shortText: 'Rechtliche Informationen gemäß §5 TMG.',
  imageLink:
    '',
  content: [
    { type: 'heading', level: 2, text: 'Webseitenbetreiber' },
    {
      type: 'paragraph',
      text: 'Michael Cherner'
    },
    { type: 'heading', level: 3, text: 'Adresse' },
    {
      type: 'paragraph',
      text: 'Venloer Straße 141\nNeuss, 41462'
    },
    { type: 'heading', level: 3, text: 'Kontakt' },
    {
      type: 'paragraph',
      text: 'E-Mail: hello@longminds.de'
    }
  ]
}

export const DataPrivacy = {
  id: 2,
  sid: 'dataPrivacy',
  url: 'datenschutz',
  header: 'DataPrivacy',
  shortText: 'Dies ist das Impressum',
  imageLink:
    'https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png',
  fcontent: [
    { type: 'heading', level: 2, text: 'Lalala Impressum' },
    {
      type: 'paragraph',
      text: "Buying your first home is a big step! Here, we'll walk you through the essential stages to make the process as smooth as possible."
    },
    { type: 'heading', level: 3, text: 'Step 1: Budgeting' },
    {
      type: 'paragraph',
      text: 'Understanding your budget is crucial before making any offers...'
    },
    {
      type: 'image',
      src: 'https://cdn.pixabay.com/photo/2015/04/20/13/18/money-731778_1280.jpg',
      alt: 'Home buying budget'
    },
    {
      type: 'list',
      items: [
        'Check the neighborhood for schools, parks, and public transport options.',
        'Consider the potential for property value growth in the area.',
        'Think about your long-term plans and how the home will meet your future needs.'
      ]
    },
    { type: 'paragraph', text: 'Good luck with your first home purchase!' }
  ]
}
