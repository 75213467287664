import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import FilterSidebar from './FilterSidebar'
import LayerSidebar from './LayerSidebar'
import ToolsSidebar from './ToolsSidebar'
import colors from '../../utils/colors'
import config from '../../utils/config'
import logoBrandText from '../../Assets/ImmoSachen_Logo_text_only.png'
import { useNavigate } from 'react-router-dom'

const Sidebar = ({
  toggleLayer,
  isSidebarOpen,
  toggleSidebar,
  handleApplyFilters,
  activeFilters,
  toggleView,
  is2DView,
  layersData,
  layerMerge
}) => {
  const { language } = useLanguage()

  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    navigate(link); // Navigate to the link when clicked
  };
  console.log('LAYERSDATA:', layersData)

  return (
    <>
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100svh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Greyed-out background
            zIndex: 2
          }}
        />
      )}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: isSidebarOpen ? '350px' : '0px',
          height: '100svh',
          backgroundColor: colors.background,
          zIndex: 3,
          transition: 'width 0.3s',
          overflow: 'hidden', // Hide content when sidebar is closed
          borderRadius: '0 15px 15px 0',
          boxShadow: '5px 20 30px rgba(0, 0, 0, 0.4)'
        }}
      >
        {isSidebarOpen && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              height: '100svh', // Subtract button height to avoid overflow issues
              overflowY: 'auto', // Enable vertical scrolling
              boxSizing: 'border-box'
            }}
          >
            <div
              style={{
                height: 40,
                width: '100%',
                display: 'flex',
                margin: '10px 0px',
                padding: '0px 17.5px',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <div
              onClick={() => handleLinkClick(config.apps.homepage)}
              >
              <img
                src={logoBrandText} // Using the variable for the image source
                alt='ImmoSachen Logo'
                style={{ height: '20px', marginRight: '8px' }}
              />
              </div>
              {/* Button for Menu */}
              <button
                onClick={toggleSidebar}
                style={{
                  border: 'none',
                  padding: '10px 0px',
                  cursor: 'pointer',
                  background: 'none',
                  color: colors.icon,
                  zIndex: 4, // Higher zIndex to be above other content
                  fontSize: '28px'
                }}
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </button>
            </div>

            <ToolsSidebar
              header={translate('mapTools', language)}
              toggleView={toggleView}
              is2DView={is2DView}
            />
            <LayerSidebar
              header={translate('layerSelection', language)}
              toggleLayer={toggleLayer}
              layersData={layersData}
              layerMerge={layerMerge}
              isLayerVisible={layersData[0].isLayerVisible}
              layerName={layersData[0].layerName}
              layerLabel={layersData[0].layerLabel}
            />
            {/* Pass the handleRangeChange callback to FilterSidebar */}
            <FilterSidebar
              onFilterChange={handleApplyFilters}
              activeFilters={activeFilters}
              header={translate('filter', language)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Sidebar
