export const toggleView = (map, is2DView, setIs2DView) => {
  if (!map) return;
  
  if (is2DView) {
    // Switch to 3D view
    map.easeTo({ pitch: 60 }); // Set the map's pitch to 60 for 3D view
    map.setPaintProperty('immo_layer-fill-extrusion', 'fill-extrusion-height', ['get', 'measuredheight']); // Use actual building heights
    setIs2DView(false); // Update state to 3D view
  } else {
    // Switch to 2D view
    map.easeTo({ pitch: 0 }); // Set the map's pitch to 0 for 2D view
    map.setPaintProperty('immo_layer-fill-extrusion', 'fill-extrusion-height', 0); // Flatten the buildings
    setIs2DView(true); // Update state to 2D view
  }
};
