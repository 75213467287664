import React from 'react';

const VersionSticker = ({wording, stickerColor}) => {
  const stickerStyle = {
    position: 'absolute',
    top: '60px',
    left: '10px',
    backgroundColor: stickerColor, // A vibrant orange to draw attention
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: 'bold',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
    cursor: 'default',
  };

  return <div style={stickerStyle}>{wording} Version</div>;
};

export default VersionSticker;
