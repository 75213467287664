import React, { useState, useEffect, useRef } from 'react'
import OutputCalculatorTop from './components/OutputCalculatorTop'
import OutputCalculatorSide from './components/OutputCalculatorSide'
import Navbar from '../components/navbar/Navbar'
import useStandardButtonStyle from '../styles/standardButtonStyle'
import config from '../utils/config'
import colors from '../utils/colors'
import useDeviceType from '../hooks/useDeviceType'
import logoBrandText from '../Assets/ImmoSachen_Logo_text_only.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faFileCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

import InputCalculatorNumbers from './components/InputCalculatorNumbers'
import VersionSticker from '../components/VersionSticker'

const Calculator = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  const [calculatorInputValues, setCalculatorInputValues] = useState({})
  const [calculationResult, setCalculationResult] = useState({})

  const { isMobile } = useDeviceType()

  const navigate = useNavigate();
  const handleLinkClick = (link) => {
    navigate(link); // Navigate to the link when clicked
  };

  const outputSectionRef = useRef(null)
  const standardButtonStyle = useStandardButtonStyle()

  const performCalculations = updatedValues => {
    const {
      immoPrice,
      coldRent,
      counties,
      interestRate,
      allocableOperatingCosts,
      operatingCosts,
      livingArea,
      realestateAgentRate,
      equity,
      plotSize,
      landShare,
      landValueAssessment,
      localRentValue,
      marginTaxRate,
      // monthlyPaymentRate,
      yearOfConstruction,
      vacancy,
      repaymentRate,
      purchaseDate,
      depreciationDegressiveEligible
    } = updatedValues

    const getCountyTaxValue = (countyKey, options) => {
      // Check if the countyKey exists in options and return the corresponding value
      if (options.hasOwnProperty(countyKey)) {
        return options[countyKey]
      }

      // Return a default value if the countyKey does not match any option
      return null // You can change this to a different default if needed
    }

    const getDepreciationRate = yearOfConstruction => {
      if (yearOfConstruction < 1925) {
        return 0.025 // 0.25%
      } else if (yearOfConstruction > 2022) {
        return 0.03 // 0.3%
      } else {
        return 0.02 // 0.2%
      }
    }

    const getDepreciationYear = yearOfConstruction => {
      if (yearOfConstruction < 1925) {
        return 40
      } else if (yearOfConstruction > 2022) {
        return 33
      } else {
        return 50
      }
    }

    const countyTaxRate = {
      value: getCountyTaxValue(
        counties.value.value,
        config.immoCalculator.filterMulti.counties.options
      ),
      steps: 0.1,
      unit: '%'
    }

    const yearlyRent = { value: coldRent?.value * 12, steps: 1, unit: '€' } // Calculate yearly rent
    const costRealestateAgent = {
      value: immoPrice?.value * realestateAgentRate?.value,
      steps: 1,
      unit: '€'
    } // Calculate real estate agent fee
    const costNotary = { value: immoPrice?.value * 0.015, steps: 1, unit: '€' } // Calculate notary cost
    const costLandRegister = {
      value: immoPrice?.value * 0.005,
      steps: 1,
      unit: '€'
    } // Calculate land register cost
    const costCountyTaxes = {
      value: immoPrice?.value * countyTaxRate?.value,
      steps: 1,
      unit: '€'
    } // Calculate taxes
    const nonAllocableOperatingCosts = {
      value: Math.max(
        0,
        operatingCosts?.value - allocableOperatingCosts?.value
      ),
      steps: 0.01,
      unit: '€'
    } //non allocatable operating costs
    const buyingCosts = {
      value:
        immoPrice?.value +
        costRealestateAgent?.value +
        costNotary?.value +
        costLandRegister?.value,
      steps: 0.01,
      unit: '€'
    }
    const effectiveRent = {
      value: yearlyRent.value * (1 - vacancy.value),
      steps: 0.01,
      unit: '€'
    }
    const acquisitionCosts = {
      value:
        immoPrice?.value +
        costRealestateAgent?.value +
        costNotary.value +
        costLandRegister.value +
        costCountyTaxes.value,
      steps: 1,
      unit: '€'
    }
    const loanAmount = {
      value: acquisitionCosts?.value - equity?.value,
      steps: 1,
      unit: '€'
    } // Calculate loan amount
    const netOperatingIncome = {
      value: effectiveRent?.value - nonAllocableOperatingCosts?.value * 12,
      steps: 0.1,
      unit: '€/Jahr'
    } // Calculate Netto-Betriebsergebnis
    const grossRentalYield = {
      value: yearlyRent?.value / immoPrice?.value,
      steps: 0.1,
      unit: '%'
    } // Calculate Bruttomietrendite
    const netRentalYield = {
      value:
        (effectiveRent.value - nonAllocableOperatingCosts?.value * 12) /
        buyingCosts?.value,
      steps: 0.01,
      unit: '%'
    } // Calculate Nettomietrendite
    const annuity = {
      value: (() => {
        const monthlyInterestRate = interestRate?.value / 12 // Umrechnung in monatlichen Zinssatz
        const monthlyRepaymentRate = repaymentRate?.value / 12 // Umwandlung der Tilgungsrate in Dezimalzahl

        // Berechnung der Annuität
        // Die Annuität ist die Summe der Zinsen und der Tilgung, berechnet als:
        const annuityValue =
          loanAmount?.value * (monthlyInterestRate + monthlyRepaymentRate)

        return annuityValue
      })(),
      steps: 0.01,
      unit: '€'
    }
    const ltvRatio = {
      value: loanAmount?.value / immoPrice?.value,
      steps: 0.1,
      unit: '%'
    } // Loan-to-Value Ratio in %
    const equityPercentage = {
      value: equity?.value / immoPrice?.value,
      steps: 0.1,
      unit: '%'
    } // Calculate equity percentage

    const costPerSquareMeter = {
      value: immoPrice?.value / livingArea?.value,
      steps: 1,
      unit: '€'
    } // Calculate cost per square meter
    const landValue = {
      value: plotSize?.value * landValueAssessment?.value * landShare?.value,
      steps: 0.01,
      unit: '€'
    }
    const depreciationRate = {
      value: getDepreciationRate(yearOfConstruction.value),
      steps: 0.0001,
      unit: '%'
    }
    const depreciationStartValue = {
      value: acquisitionCosts.value - landValue.value,
      steps: 1,
      unit: '€'
    }
    const depreciationDuration = {
      value: getDepreciationYear(yearOfConstruction.value),
      steps: 1,
      unit: 'Jahr(e)'
    }
    const depreciationMonthsStartYear = (() => {
      const buyingDate = new Date(purchaseDate?.value) // Convert Unix to JS Date (timestamp is in seconds)
      const purchaseMonth = buyingDate.getMonth() // Get the month (0 = January, 11 = December)

      const remainingMonths = 12 - purchaseMonth // Remaining months including the current month
      return {
        value: remainingMonths, // The number of months remaining in the year
        steps: 1, // Single-step counting months
        unit: 'months' // Unit of measurement
      }
    })()

    const calculateAnnualInvestmentData = (
      startYear,
      depreciableAmount,
      loanAmount,
      interestRate,
      annuity,
      depreciationRate,
      marginTaxRate,
      yearlyRent,
      vacancy,
      nonAllocableOperatingCosts,
      depreciationRemainingMonthsStartYear,
      depreciationDegressiveEligible,
      depreciationDuration
    ) => {
      const investmentData = []
      let year = startYear // Start from the specified year
      let depreciationRestValue = depreciableAmount
      let firstLoanAmountZeroIndex = null
      let remainingYears = depreciationDuration
      let depreciationRestLinear = depreciableAmount
      let isFirstYear = true

      // Effective rent after considering vacancy
      const effectiveRent = yearlyRent * (1 - vacancy) // Effective rent after considering vacancy

      while (loanAmount > 0 || depreciationRestValue > 0) {
        const interest = loanAmount * interestRate // Interest paid for the year
        // Calculate repayment based on annuity but ensure it's not greater than remaining debt
        let repayment = Math.max(0, annuity * 12 - interest)

        if (repayment > loanAmount) {
          repayment = loanAmount // Cap repayment to the remaining debt
        }
        const remainingDebt = loanAmount - repayment // Remaining debt at the end of the year

        let depreciation
        let depreciationFraction = 1 // Full depreciation for normal years

        // Apply fractional depreciation in the first year if necessary
        if (
          isFirstYear &&
          depreciationRemainingMonthsStartYear >= 1 &&
          depreciationRemainingMonthsStartYear <= 12
        ) {
          depreciationFraction = depreciationRemainingMonthsStartYear / 12
          isFirstYear = false // Only apply fraction in the first year
        }

        // Check if eligible for degressive depreciation and if building year is 2023 or higher
        if (depreciationDegressiveEligible === 1 && startYear >= 2023) {
          if (year <= 2029) {
            // Use degressive depreciation (5% of remaining value)
            depreciation = depreciationRestValue * 0.05 * depreciationFraction
            depreciationRestLinear = Math.max(
              depreciationRestValue - depreciation,
              0
            )
            remainingYears--
          } else {
            // Switch to linear depreciation after 2029
            depreciation =
              (depreciationRestLinear / remainingYears) * depreciationFraction
          }
        } else {
          // Use linear depreciation: depreciableAmount * depreciationRate
          depreciation =
            depreciableAmount * depreciationRate * depreciationFraction
        }
        //const depreciation = depreciableAmount * depreciationRate
        depreciationRestValue -= depreciation
        depreciationRestValue = Math.max(depreciationRestValue, 0)

        //const depreciation = initialDebt * depreciationRate
        const preTaxProfit =
          effectiveRent -
          nonAllocableOperatingCosts * 12 -
          interest -
          depreciation // Profit before tax
        const tax = preTaxProfit * marginTaxRate // Tax calculated on profit
        const preTaxCashflow =
          effectiveRent - nonAllocableOperatingCosts * 12 - interest - repayment // Cash flow before tax
        const netCashflow =
          effectiveRent - nonAllocableOperatingCosts * 12 - interest - tax // Net cash flow
        const afterTaxProfit = preTaxCashflow - tax // Profit after tax

        investmentData.push({
          year,
          loanAmount: parseFloat(loanAmount.toFixed(2)),
          interest: parseFloat(interest.toFixed(2)),
          repayment: parseFloat(repayment.toFixed(2)),
          depreciation: parseFloat(depreciation.toFixed(2)),
          depreciationRestValue: parseFloat(depreciationRestValue.toFixed(2)),
          remainingDebt: parseFloat(remainingDebt.toFixed(2)),
          preTaxProfit: parseFloat(preTaxProfit.toFixed(2)),
          tax: parseFloat(tax.toFixed(2)),
          preTaxCashflow: parseFloat(preTaxCashflow.toFixed(2)),
          netCashflow: parseFloat(netCashflow.toFixed(2)),
          afterTaxProfit: parseFloat(afterTaxProfit.toFixed(2))
        })

        if (remainingDebt <= 0 && firstLoanAmountZeroIndex === null) {
          firstLoanAmountZeroIndex = investmentData.length // Store the index where loanAmount becomes 0
        }
        // Update initialDebt for the next year
        loanAmount = remainingDebt
        year++ // Increment year for the next iteration
        // If remainingDebt is less than or equal to zero, exit the loop
        if (remainingDebt <= 0 && depreciationRestValue <= 0) {
          break
        }
        if (year > 2080) {
          break
        }
      }

      // Calculate the credit duration (length of array until loanAmount is 0)
      const creditDuration = {
        value: firstLoanAmountZeroIndex || investmentData.length, // Use index where loanAmount is 0, or full array length if loanAmount never reaches 0
        steps: 1,
        unit: 'Jahr(e)'
      }

      return { investmentData, creditDuration }
    }

    const {
      investmentData: investmentDataResult,
      creditDuration: calculatedCreditDuration
    } = calculateAnnualInvestmentData(
      2024,
      depreciationStartValue?.value,
      loanAmount?.value,
      interestRate?.value,
      annuity?.value,
      depreciationRate?.value,
      marginTaxRate?.value,
      yearlyRent?.value,
      vacancy?.value,
      nonAllocableOperatingCosts?.value,
      depreciationMonthsStartYear?.value,
      depreciationDegressiveEligible?.value,
      depreciationDuration?.value
    )

    const investmentData = {
      value: investmentDataResult,
      steps: 0.001,
      unit: '%'
    }
    const creditDuration = {
      value: calculatedCreditDuration.value,
      steps: 1,
      unit: 'Jahr(e)'
    }
    const cocReturn10 = {
      value:
        (netOperatingIncome?.value -
          investmentData.value
            .slice(0, 10) // Get the first 10 years of data
            .reduce((sum, yearData) => sum + yearData.interest, 0) /
            10) / // Sum up the net cashflow for the first 10 years) /
        equity?.value,
      steps: 0.01,
      unit: '%'
    } // Calculate Eigenkapitalrendite
    const taxes10 = {
      value: investmentData.value
        .slice(0, 10) // Get the first 10 years of data
        .reduce((sum, yearData) => sum + yearData.tax, 0), // Sum up the taxes for the first 10 years
      unit: '€', // Tax savings in currency
      steps: 0.01 // Set precision steps if needed
    }
    const avgTaxes10 = {
      value:
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.tax, 0) / 10, // Sum up the taxes for the first 10 years
      unit: '€', // Tax savings in currency
      steps: 0.01 // Set precision steps if needed
    }
    const repaymentCoverageRatio10 = {
      value:
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.netCashflow, 0) / // Sum up the net cashflow for the first 10 years
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.repayment, 0), // Sum up the repayment for the first 10 years // Divide net cashflow by repayment
      unit: '%', // Result as a percentage
      steps: 0.01 // Set precision steps if needed
    }
    const avgNetCashflow10 = {
      value:
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.netCashflow, 0) / 10, // Sum up the net cashflow and build average for the first 10 years
      unit: '€', // Result as a percentage
      steps: 0.01 // Set precision steps if needed
    }
    const preTaxProfit10 = {
      value: investmentData.value
        .slice(0, 10) // Get the first 10 years of data
        .reduce((sum, yearData) => sum + yearData.preTaxProfit, 0), // Sum up the net cashflow for the first 10 years
      unit: '€', // Result as a percentage
      steps: 0.01 // Set precision steps if needed
    }
    const avgRepaymentRate10 = {
      value:
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.repayment, 0) / 10, // Sum up the net cashflow for the first 10 years
      unit: '€', // Result as a percentage
      steps: 0.01 // Set precision steps if needed
    }
    const avgInterest10 = {
      value:
        investmentData.value
          .slice(0, 10) // Get the first 10 years of data
          .reduce((sum, yearData) => sum + yearData.interest, 0) / 10, // Sum up the net cashflow for the first 10 years
      unit: '€', // Result as a percentage
      steps: 0.01 // Set precision steps if needed
    }

    // Return the calculation result object directly
    return {
      coldRent,
      immoPrice,
      counties,
      interestRate,
      equity,
      livingArea,
      realestateAgentRate,
      yearlyRent,
      effectiveRent,
      loanAmount,
      equityPercentage,
      costRealestateAgent,
      costNotary,
      costLandRegister,
      costPerSquareMeter,
      costCountyTaxes,
      operatingCosts,
      allocableOperatingCosts,
      nonAllocableOperatingCosts,
      plotSize,
      landShare,
      landValueAssessment,
      localRentValue,
      marginTaxRate,
      // monthlyPaymentRate,
      yearOfConstruction,
      vacancy,
      repaymentRate,
      buyingCosts,
      netOperatingIncome,
      grossRentalYield,
      netRentalYield,
      cocReturn10,
      ltvRatio,
      annuity,
      landValue,
      depreciationRate,
      depreciationStartValue,
      investmentData,
      creditDuration,
      purchaseDate,
      depreciationMonthsStartYear,
      taxes10,
      avgTaxes10,
      repaymentCoverageRatio10,
      avgNetCashflow10,
      preTaxProfit10,
      avgRepaymentRate10,
      avgInterest10,
      depreciationDegressiveEligible
      //pass all Params here
    }
  }

  // Use useEffect to trigger calculations whenever input values change
  useEffect(() => {
    if (Object.keys(calculatorInputValues).length > 0) {
      const result = performCalculations(calculatorInputValues)
      setCalculationResult(result)
    }
  }, [calculatorInputValues]) // Trigger when input values change

  const handleInputValuesChange = updatedValues => {
    setCalculatorInputValues(updatedValues)
    console.log('Updated Input Values in Calculator Component:', updatedValues)
  }

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen)
  }

  const scrollToResults = () => {
    if (outputSectionRef.current) {
      outputSectionRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    console.log('LALA', outputSectionRef.current)
  }

  return (
    <div>
      <div
        style={{
          position: 'absolute',
          top: 5,
          width: '100%',
          height: 50,
          display: 'flex',
          justifyContent: 'flex-end',
          padding: isMobile ? '10px 10px 0 10px' : '10px 10px 0px 10px',
          zIndex: 1,
          boxSizing: 'border-box'
        }}
      >
        <div onClick={toggleNavbar} style={standardButtonStyle}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        <Navbar isNavbarOpen={isNavbarOpen} toggleNavbar={toggleNavbar} />
      </div>
      <div style={{ position: 'relative', width: '100%' }}>
        {/* Logo Container positioned absolutely */}
        <div
          onClick={() => handleLinkClick(config.apps.homepage)}
          style={{
            position: 'absolute',
            left: 5,
            top: 10,
            display: isMobile ? 'none' : 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <img
            src={logoBrandText} // Using the variable for the image source
            alt='ImmoSachen Logo'
            style={{ height: '25px', marginRight: '8px' }}
          />
        </div>

        {/* Centered Heading Wrapper */}
        <div
          style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            flex: 1
          }}
        >
          <h1 style={{ margin: '15px 0' }}>Investitionsrechner</h1>
        </div>
      </div>

      {/* Output Section */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '20px',
          maxWidth: 1200,
          padding: '0 20px',
          margin: '0 auto 20px auto'
        }}
      >
        <OutputCalculatorTop
          header={'Bewertung'}
          calculatedValues={calculationResult}
        />
      </div>

      {/* Input and Output Section */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '20px',
          maxWidth: 1200,
          padding: '0 20px',
          margin: '0 auto 20px auto'
        }}
      >
        <InputCalculatorNumbers
          header={'inputParameter'}
          onInputValuesChange={handleInputValuesChange}
        />
        <div ref={outputSectionRef}></div>
        <OutputCalculatorSide
          header={'outputParameter'}
          calculatedValues={calculationResult}
        />
        {/* Back to Results Button for Mobile */}
        {isMobile && (
          <div
            style={{
              ...standardButtonStyle,
              position: 'fixed',
              bottom: 20,
              right: 10,
              zIndex: 1000,
              fontSize: '20px'
            }}
            onClick={scrollToResults}
          >
            <FontAwesomeIcon icon={faFileCircleCheck} size='lg' />
          </div>
        )}
        {/* <InputCalculator
          header={'Input Parameter'}
          onInputValuesChange={handleInputValuesChange}
        /> */}
        <VersionSticker wording={"Beta"} stickerColor={colors.betaColor} />
      </div>
    </div>
  )
}

export default Calculator
