import colors from '../utils/colors'
import useDeviceType from '../hooks/useDeviceType'

const useStandardButtonStyle = () => {
  const { isMobile } = useDeviceType()
  
  return {
    cursor: 'pointer',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.buttons.primary,
    color: colors.icon,
    zIndex: 1,
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '12px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
    width: 50,
    height: isMobile ? 45  : 45,
  }
}

export default useStandardButtonStyle
