import React, { useState, useEffect } from 'react'
import colors from '../../utils/colors'
import config from '../../utils/config'
import InputSelect from './InputSelect'
import InputTextCalculator from './InputTextCalculator'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import StyledDatePicker from './StyledDatePicker'
import StyledCheckbox from './StyledCheckbox'

// Extract the configs outside the component
const { filterSlider, filterSliderOptions, filterSliderSpecial, filterMulti } =
  config.immoCalculator

const immoPriceConfig = filterSlider.immoPrice
const coldRentConfig = filterSlider.coldRent
const livingAreaConfig = filterSlider.livingArea
const realestateAgentRateConfig = filterSliderOptions.realestateAgentRate
const interestRateConfig = filterSliderOptions.interestRate
const equityConfig = filterSliderSpecial.equity
const operatingCostsConfig = filterSliderSpecial.operatingCosts
const allocableOperatingCostsConfig =
  filterSliderSpecial.allocableOperatingCosts
const plotSizeConfig = filterSliderSpecial.plotSize
const landShareConfig = filterSliderSpecial.landShare
const landValueAssessmentConfig = filterSliderSpecial.landValueAssessment
const localRentValueConfig = filterSliderSpecial.localRentValue
const marginTaxRateConfig = filterSliderSpecial.marginTaxRate
//const monthlyPaymentRateConfig = filterSliderSpecial.monthlyPaymentRate
const repaymentRateConfig = filterSliderSpecial.repaymentRate
const yearOfConstructionConfig = filterSliderSpecial.yearOfConstruction
const vacancyConfig = filterSliderSpecial.vacancy
const countiesConfig = filterMulti.counties
const purchaseDateConfig = filterSliderSpecial.purchaseDate
const depreciationDegressiveEligibleConfig = filterSliderSpecial.depreciationDegressiveEligible

const InputCalculatorNumbers = ({ header, onInputValuesChange }) => {
  const { language } = useLanguage()
  const [inputValues, setInputValues] = useState(() => {
    const initialValues = {}

    // Use the pre-defined configs
    Object.keys(filterSlider).forEach(key => {
      const sliderConfig = filterSlider[key]
      initialValues[sliderConfig.parameter] = {
        value: sliderConfig.default,
        steps: sliderConfig.steps,
        unit: sliderConfig.unit
      }
    })

    Object.keys(filterSliderOptions).forEach(key => {
      const sliderConfig = filterSliderOptions[key]
      initialValues[sliderConfig.parameter] = {
        value: sliderConfig.default,
        steps: sliderConfig.steps,
        unit: sliderConfig.unit
      }
    })

    Object.keys(filterSliderSpecial).forEach(key => {
      const sliderConfig = filterSliderSpecial[key]
      initialValues[sliderConfig.parameter] = {
        value: sliderConfig.default,
        steps: sliderConfig.steps,
        unit: sliderConfig.unit
      }
    })

    // Set the default value for multi-select
    initialValues[countiesConfig.parameter] = { label: '', value: '' }

    return initialValues
  })

  const valueHandler = (key, value, steps, unit) => {
    setInputValues(prev => {
      const updatedValues = {
        ...prev,
        [key]: {
          value: isNaN(value) ? value : Number(value),
          steps,
          unit
        }
      }

      onInputValuesChange(updatedValues)
      return updatedValues
    })
  }

  // Function to dynamically generate InputTextCalculator components
  const generateInputComponents = (
    configArray,
    inputValues,
    valueHandler,
    subHeader
  ) => {
    return (
      <div style={{ width: '100%' }}>
        {/* Subheader */}
        <div
          style={{
            marginTop: 20,
            marginBottom: '8px',
            fontSize: '16px',
            color: colors.text,
            fontWeight: 'bold',
            // borderBottom: `1px solid ${colors.border}`,
            padding: '4px',
            textAlign: 'center',
            backgroundColor: colors.backgroundSubHeader,
            borderRadius: 10
          }}
        >
          {translate(subHeader, language)}
        </div>
        <div
          style={{
            marginTop: '0px',
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px'
          }}
        >
          {configArray?.map(config => { 
            // Check if the unit is 'date'
          if (config.unit === 'date') {
            return (
              <StyledDatePicker
                key={config.parameter}
                value={inputValues[config.parameter]?.value}
                parameter={config.parameter}
                min={config.min}
                max={config.max}
                steps={config.steps}
                unit={config.unit}
                setValue={valueHandler}
                minWidth={200}
              />
            );
          } else if (config.unit === 'boolean') {
            return (
              <StyledCheckbox 
                key={config.parameter}
                parameter={config.parameter}
                value={inputValues[config.parameter]?.value}
                steps={'none'}
                unit={'boolean'}
                setValue={valueHandler}
                minWidth={200}
              />
            );
          }
          return (
            <InputTextCalculator
              key={config.parameter}
              parameter={config.parameter}
              value={inputValues[config.parameter]?.value}
              min={config.min}
              max={config.max}
              unit={config.unit}
              steps={config.steps}
              setValue={valueHandler}
              minWidth={200}
            />
          );
          })}
        </div>
      </div>
    )
  }

  useEffect(() => {
    Object.keys(inputValues).forEach(key => {
      const { value, steps, unit } = inputValues[key]
      valueHandler(key, value, steps, unit)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        flex: '1 1 400px',
        maxWidth: '100%',
        margin: '0 auto'
      }}
    >
      {header && (
        <div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '20px',
              color: colors.text
            }}
          >
            {translate(header, language)}
          </div>
          <div
            style={{
              margin: '20px 0px 0 auto',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px'
            }}
          >
            {generateInputComponents(
              [
                immoPriceConfig,
                livingAreaConfig,
                plotSizeConfig,
                landShareConfig,
                yearOfConstructionConfig
              ],
              inputValues,
              valueHandler,
              'propertyInformation'
            )}
            {generateInputComponents(
              [coldRentConfig, operatingCostsConfig,
                allocableOperatingCostsConfig, localRentValueConfig, vacancyConfig],
              inputValues,
              valueHandler,
              'rentalAndIncome'
            )}
            {generateInputComponents(
              [
                equityConfig,
                interestRateConfig,
                repaymentRateConfig,
                realestateAgentRateConfig,
                purchaseDateConfig
              ],
              inputValues,
              valueHandler,
              'financialDetails'
            )}

            {generateInputComponents(
              [landValueAssessmentConfig, marginTaxRateConfig, depreciationDegressiveEligibleConfig],
              inputValues,
              valueHandler,
              'taxAndValuation'
            )}

            <div style={{ width: '100%' }}>
              {/* Subheader */}
              <div
                style={{
                  marginTop: 20,
                  marginBottom: '8px',
                  fontSize: '16px',
                  color: colors.text,
                  fontWeight: 'bold',
                  //borderBottom: `1px solid ${colors.border}`,
                  padding: '4px',
                  textAlign: 'center',
                  backgroundColor: colors.backgroundSubHeader,
                  borderRadius: 10
                }}
              >
                {translate('geographicalInformation', language)}
              </div>
              <div
                style={{
                  marginTop: '0px',
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px'
                }}
              >
                <InputSelect
                  parameter={countiesConfig.parameter}
                  options={countiesConfig.options}
                  setValues={valueHandler}
                />
              </div>
            </div>

            {/* Additional InputTextCalculator components can be added here */}
          </div>
        </div>
      )}
    </div>
  )
}

export default InputCalculatorNumbers
