import React from 'react';
import { Link } from 'react-router-dom';
import colors from '../utils/colors'; // Adjust the import path as necessary

const HoverLink = ({ to, children }) => {
  const linkStyle = {
    textDecoration: 'none', // Remove underline from the link
    transition: 'color 0.3s, transform 0.5s, font-weight 0.3s', // Include transitions
    color: 'inherit', // Default color
    whiteSpace: 'nowrap', // Prevent text wrapping
    marginBottom: '5px', // Space between links
    display: 'inline-block', // Ensure the link only takes necessary width
  };

  const handleHover = (e) => {
    e.currentTarget.style.color = colors.linkHover; // Change color on hover
    e.currentTarget.style.transform = 'scale(1.01)'; // Scale the link
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.color = 'inherit'; // Reset color when not hovering
    e.currentTarget.style.fontWeight = 'normal'; // Reset font weight
    e.currentTarget.style.transform = 'scale(1)'; // Reset scale
  };

  return (
    <Link 
      to={to} 
      style={linkStyle} 
      onMouseOver={handleHover} 
      onMouseOut={handleMouseLeave}
    >
      {children}
    </Link>
  );
};

export default HoverLink;
