import React from 'react';
import colors from '../../utils/colors'; // Assuming colors.background is part of your color utils
import { addFilterValue } from '../../utils/filters/addFilterValue';
import { removeFilterValue } from '../../utils/filters/removeFilterValue';

const LegendElement = ({ filterKey, applyFilter, label, value, options, color, activeFilters, isFilter }) => {
  const filterHandler = () => {
    let newFilters;

    if (isFilter) {
      newFilters = removeFilterValue(activeFilters.filterRange, filterKey, value, options)
    } else {
      newFilters = addFilterValue(activeFilters.filterRange, filterKey, value)
    }
    applyFilter(newFilters)
  }

  return (
    <div 
      onClick={filterHandler}
      style={{
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0, // Prevent shrinking if needed in a flex container
      borderRadius: 5,
      backgroundColor: colors.background,
      height: 40,
      margin: '2px 5px',
      padding: '10px',
      minWidth: 100,
      maxWidth: 200,
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
      border: isFilter ? `2px solid ${color}` : `2px solid ${colors.background}`,  // Border with color if it's active
      opacity: isFilter ? 1 : 0.6,  // Dim the element when inactive
      cursor: 'pointer',  // Change the cursor based on isFilter
      position: 'relative',  // Allow for pseudo-elements or overlays
      whiteSpace: 'nowrap' // Prevent label from wrapping
    }}>

      {/* Color indicator */}
      <div style={{
        height: 20,
        width: 20,
        borderRadius: '50%',
        backgroundColor: color,
        marginRight: 10,
        zIndex: 1,  // Ensure it's above the overlay
      }}></div>

      {/* Label */}
      <span style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: colors.text,  // You can adjust this to fit your color theme
        whiteSpace: 'nowrap', // Ensure the label doesn't wrap
        overflow: 'hidden', // Optional: prevent overflowing text
        textOverflow: 'ellipsis', // Optional: add ellipsis if text is too long
        zIndex: 1,  // Ensure it's above the overlay
      }}>
        {label}
      </span>
    </div>
  );
};

export default LegendElement;
