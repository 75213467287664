import React, { createContext, useState, useContext } from 'react'

// Create the Language Context
const LanguageContext = createContext()

// Language Provider Component
export const LanguageProvider = ({ children }) => {
  // Set the default language, e.g., 'en'
  const [language, setLanguage] = useState('de')

  // Function to change the language globally
  const changeLanguage = lang => setLanguage(lang)

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

// Custom hook to use the language context
export const useLanguage = () => useContext(LanguageContext)
