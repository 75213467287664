import config from './config'

const tileServerUtils = {
  // Method to get source ID and optionally fetch the tile URL
  async getTileUrl (key) {
    const tileserverUrlArray = config.tileserver.layers

    // If key exists in the response
    if (key in tileserverUrlArray) {
      const sourceIdUrl = tileserverUrlArray[key].url

      // fetch and return the URL inside the "tiles" property of the sourceIdUrl
      const tileUrlResponse = await fetch(sourceIdUrl)
      if (!tileUrlResponse.ok) {
        throw new Error('Failed to fetch tile URL')
      }

      const tileData = await tileUrlResponse.json()
      if (tileData.tiles && tileData.tiles.length > 0) {
        return tileData.tiles[0] // Return the URL at the first position of the tiles array, as there should only be one URL
      } else {
        throw new Error('No tiles URL found in the response')
      }

    } else {
      throw new Error(`Key "${key}" not found in the response`)
    }
  },

  async getSourceUrl (key) {
    const tileserverUrlArray = config.tileserver.layers

    // If key exists in the response
    if (key in tileserverUrlArray) {
      const sourceIdUrl = tileserverUrlArray[key].url

      return sourceIdUrl
    } else {
      throw new Error(`Key "${key}" not found in the response`)
    }
  },
}

export default tileServerUtils
