import React from 'react'
import colors from '../../utils/colors'
import KPIBar from '../../components/KPIBar'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

const OutputCalculatorTop = ({ header, calculatedValues }) => {
  const { language } = useLanguage()

  const getStatusText = (value, lowerBoundary, upperBoundary) => {
    if (value < lowerBoundary) {
      return translate('evaluationCalculatorResultsLow', language)
    } else if (value >= lowerBoundary && value <= upperBoundary) {
      return translate('evaluationCalculatorResultsMedium', language)
    } else {
      return translate('evaluationCalculatorResultsHigh', language)
    }
  }
  const getFillPercentage = (value, lowerBoundary, upperBoundary) => {
    return ((value - lowerBoundary) / (upperBoundary - lowerBoundary)) * 100
  }

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        flex: '1 1 500px', // Allows each component to grow/shrink, min-width of 300px
        maxWidth: '100%',
        margin: '0 auto'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText
          }}
        >
          {header}
          <div style={{ margin: '20px 18px 0 auto', display: 'flex' }}></div>
          {calculatedValues.grossRentalYield ? (
            <KPIBar
              barLabel={'Bruttomietrendite'}
              value={calculatedValues.grossRentalYield?.value}
              barColors={[
                colors.calculator.low,
                colors.calculator.medium,
                colors.calculator.high
              ]}
              marginTop={'0px'}
              min={0.01}
              max={0.08}
              boundaryBottom={43}
              boundaryTop={70}
              showLabel={false}
            />
          ) : (
            <p>No gross rental yield available</p>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                padding: '20px',
                margin: '10px 0px',
                backgroundColor: colors.backgroundContainer,
                borderRadius: '15px',
                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                flex: '1 1 200px', // Allows each component to grow/shrink, min-width of 500px
                fontSize: '16px', // Increased font size for better readability
                lineHeight: '1.6', // Adjusted line height for better spacing between lines
                color: colors.text // Ensure the text color fits well with the background
              }}
            >
              {' '}
              {getStatusText(
                getFillPercentage(
                  calculatedValues.grossRentalYield?.value,
                  0.01,
                  0.08
                ),
                43,
                70
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OutputCalculatorTop
