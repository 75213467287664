import React from 'react';
import { translate } from '../../utils/translationManager';
import colors from '../../utils/colors';
import { useLanguage } from '../../context/LanguageContext';

const ToggleSwitch = ({ isLayerVisible, toggleLayer, layerName, label }) => {
  const { language } = useLanguage();

  return (
    <div
      onClick={() => toggleLayer(layerName)}
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '20px',
      }}
    >
      {/* Toggle Slider */}
      <div
        style={{
          position: 'relative',
          width: '40px',
          height: '20px',
          borderRadius: '15px',
          backgroundColor: isLayerVisible ? '#4db6ac' : colors.icon, // Background color changes on toggle
          transition: 'background-color 0.3s ease', // Smooth background transition
          padding: '0px',
          cursor: 'pointer',
        }}
      >
        {/* Sliding Circle */}
        <div
          style={{
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            backgroundColor: '#fff', // Circle color
            position: 'absolute',
            top: '50%',
            transform: `translate(${isLayerVisible ? '20px' : '0'}, -50%)`, // Slide the circle on toggle
            transition: 'transform 0.3s ease', // Smooth sliding transition
            border: '2px solid #4db6ac'
          }}
        />
      </div>

      {/* Label Text */}
      <span
        style={{
          marginLeft: '10px', // Spacing between the toggle and text
          fontSize: '16px',
          color: colors.text, // Adjust the color of the text based on your theme
        }}
      >
        {translate(label, language)}
      </span>
    </div>
  );
};

export default ToggleSwitch;
