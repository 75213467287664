import React from 'react';
import colors from '../utils/colors';
import { translate } from '../utils/translationManager'
import { useLanguage } from '../context/LanguageContext'

// Sample data for key facts
const initialFacts = [
  { title: 'amountOfBuildings', value: "8.627.327" },
  { title: 'amountOfParcels', value: "9.366.605" },
  { title: 'propsPerBuilding', value: "300+" },
];

const KeyFactsBar = () => {
  const { language } = useLanguage()

  const factStyle = {
    flex: '1 1 200px', // Flex-grow, flex-shrink, flex-basis
    margin: '0 10px',
    padding: '10px',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const titleStyle = {
    fontWeight: 'bold',  // Make the title bold
    display: 'block',     // Ensure the title is displayed above the value
  };

  const valueStyle = {
    fontSize: '1.5em',  // Optionally increase the font size for value
    color: '#333',      // Optional color for better visibility
  };

  return (
    <div style={{ backgroundColor: colors.backgroundSecondary , width: '100%', maxWidth: 'none', padding: '20px' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '20px', // Space between items
      }}>
        {initialFacts.map((fact, index) => (
          <div key={index} style={factStyle}>
            <span style={titleStyle}>{translate(fact.title, language)}</span>
            <span style={valueStyle}>{fact.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyFactsBar;
