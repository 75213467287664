import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './StyledDatePicker.css'
import colors from '../../utils/colors'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import HeaderWithInfoIcon from '../../components/HeaderWithInfoIcon'

const StyledDatePicker = ({
  value,
  parameter,
  min,
  max,
  steps,
  unit,
  setValue,
  minWidth
}) => {
  const { language } = useLanguage()

  // Convert min and max dates to Date objects
  const minDate = min ? new Date(min) : null
  const maxDate = max ? new Date(max) : null

  const [selectedDate, setSelectedDate] = useState(value || new Date())

  const handleDateChange = date => {
    setValue(parameter, date, steps, unit)
    setSelectedDate(date)
  }

  return (
    <div
      style={{
        marginTop: '10px',
        flex: `1 1 calc(${minWidth}px + 60px`,
        minWidth: minWidth
      }}
    >
      {/* Header */}
      <div
        style={{
          display: 'flex',
          fontWeight: 'bold',
          fontSize: '14px',
          marginBottom: '10px',
          color: colors.text
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <HeaderWithInfoIcon parameter={parameter} />
        </div>
      </div>
      {/* Input and Unit Container */}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderColor: colors.buttons.primary,
            borderWidth: '1px',
            borderRadius: '5px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            minHeight: '50px',
            marginRight: '50px'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minHeight: '50px',
              borderRadius: '5px'
            }}
          >
            {/* DatePicker input field */}
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat='dd.MM.yyyy'
              minDate={minDate}
              maxDate={maxDate}
              className='datePickerCustomClass'
              customInput={
                <input
                  style={{
                    width: 'calc(100% - 20px)',
                    height: '100%',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '0px 10px',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    color: colors.text1,
                    cursor: 'pointer'
                  }}
                  readOnly
                />
              }
            />

            {/* Increment button (not needed for DatePicker, but keeping consistent style) */}
            <div
              style={{
                backgroundColor: colors.buttons.primary,
                color: colors.text,
                borderRadius: '3px',
                height: '100%',
                minWidth: '40px',
                minHeight: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: '0 0 auto',
                userSelect: 'none'
              }}
            >
              📅
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StyledDatePicker
