export const addFilterValue = (filterRange, filterKey, valueToAdd) => {
    // Create a copy of the filterRange array to modify
    const updatedFilterRange = [...filterRange];
    
    // Find the index of the filter with the given key
    const filterIndex = updatedFilterRange.findIndex(filter => filter.key === filterKey);
  
    if (filterIndex === -1) {
      // If the key does not exist, add a new filter entry
      updatedFilterRange.push({
        key: filterKey,
        value: [valueToAdd]
      });
    } else {
      // If the key exists, update the value array
      const filter = updatedFilterRange[filterIndex];
  
      // Check if the value already exists
      if (!filter.value.includes(valueToAdd)) {
        // If not, add the new value
        filter.value = [...filter.value, valueToAdd];
      }
    }
  
    return updatedFilterRange;
  };
  