const articles = [
    {
      id: 1,
      sid: "ID123",
      url: 'build_home',
      date: '2024-11-15',
      header: "How to Buy Your First Home",
      shortText: "Learn the essential steps for purchasing your first property, from budgeting to signing the contract.",
      imageLink: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png",
      content: [
        { type: 'heading', level: 2, text: "Lalala Impressum" },
        { type: 'paragraph', text: "Buying your first home is a big step! Here, we'll walk you through the essential stages to make the process as smooth as possible." },
        { type: 'heading', level: 3, text: "Step 1: Budgeting" },
        { type: 'paragraph', text: "Understanding your budget is crucial before making any offers..." },
        { type: 'image', src: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png", alt: "Home buying budget" },
        {
          type: 'list',
          items: [
            "Check the neighborhood for schools, parks, and public transport options.",
            "Consider the potential for property value growth in the area.",
            "Think about your long-term plans and how the home will meet your future needs."
          ]
        },
        { type: 'paragraph', text: "Good luck with your first home purchase!" }
      ],
      categories: ["Immobilieninvestment"]
    },    
    {
      id: 2,
      sid: "ID124",
      url: 'lalaTest',
      date: '2024-11-15',
      header: "Understanding Real Estate Investment",
      shortText: "An introduction to the fundamentals of investing in real estate, including property types, risks, and returns.",
      imageLink: "https://cdn.pixabay.com/photo/2013/07/12/17/47/test-pattern-152459_1280.png",
      content: [
        { type: 'heading', level: 2, text: "Lalala Impressum" },
        { type: 'paragraph', text: "Buying your first home is a big step! Here, we'll walk you through the essential stages to make the process as smooth as possible." },
        {
          type: 'list',
          items: [
            "Check the neighborhood for schools, parks, and public transport options.",
            "Consider the potential for property value growth in the area.",
            "Think about your long-term plans and how the home will meet your future needs."
          ]
        },
        { type: 'paragraph', text: "Good luck with your first home purchase!" }
      ],
      categories: ["Immobilieninvestment", "Steuerliche Aspekte"]
    },
    // Add more articles as needed
  ];
  
  export default articles;
  