import React, { useState } from 'react'
import colors from '../../utils/colors'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import './StyledCheckbox.css'
import HeaderWithInfoIcon from '../../components/HeaderWithInfoIcon'

const StyledCheckbox = ({
  value,
  parameter,
  setValue,
  unit,
  steps,
  minWidth
}) => {
  const { language } = useLanguage()
  const [isChecked, setIsChecked] = useState(value || false)

  const handleCheckboxChange = event => {
    const checked = event.target.checked
    setValue(parameter, checked, steps, unit)
    setIsChecked(checked)
  }

  return (
    <div
      style={{
        marginTop: '10px',
        flex: `1 1 calc(${minWidth}px + 60px)`,
        minWidth: minWidth
      }}
    >
      {/* Header */}
      <div
        style={{
          display: 'flex',
          fontWeight: 'bold',
          fontSize: '14px',
          marginBottom: '10px',
          color: colors.text
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          <HeaderWithInfoIcon parameter={parameter} />
        </div>
      </div>

      {/* Checkbox and label container */}
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderColor: colors.buttons.primary,
            borderWidth: '1px',
            borderRadius: '5px',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            minHeight: '50px',
            minWidth: '200px',
            marginRight: '50px',
            padding: '0px 10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              minHeight: '50px',
              borderRadius: '5px'
            }}
          >
            {/* Checkbox */}
            <input
              type='checkbox'
              checked={isChecked}
              onChange={handleCheckboxChange}
              className='custom-checkbox'
              style={{
                width: '20px',
                height: '20px',
                marginRight: '10px',
                cursor: 'pointer'
              }}
            />
            {/* Checkbox label */}
            <label
              style={{
                fontSize: '20px',
                fontWeight: 'bold',
                color: colors.text1,
                cursor: 'pointer'
              }}
              onClick={() => setIsChecked(!isChecked)}
            >
              {isChecked
                ? translate('yes', language)
                : translate('no', language)}
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StyledCheckbox
