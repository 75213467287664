const enTranslations = {
  // general
  "searchLabel": "Search...",
  "label": "Label",
  "color": "Color",

  // Object selection
  "invest_potential": "Investment Potential",
  "life_quality": "Quality of Life",
  "location": "Location",
  "sustainability": "Sustainability",
  "measuredheight": "Building Height",
  "building_group_type": "Building Type",
  // add more keys as needed
  "public": "Public Building",
  "miscellaneous": "Miscellaneous",
  "residential": "Residential Building",
  "garage": "Garage",
  "industry": "Industry",
  "business": "Business",
  "parking": "Parking",
  "leisure": "Leisure",
  // filter
  "filter": "Filter",
  "applyFilter": "Apply Filters",
  "clearFilter": "Clear Filters",

  // Multiselect
  "allItemsAreSelected": "All items are selected.",
  "clearSearch": "Clear Search",
  "clearSelected": "Clear Selected",
  "noOptions": "No Options",
  "search": "Search",
  "selectAll": "Select All",
  "selectAllFiltered": "Select All (Filtered)",
  "selectSomeItems": "Select...",
  "create": "Create",

  //Select
  "selectOptionCounty": "Select County",

  "mapTools": "Map Tools",
  layerSelection: 'Layer Selection',

  "de": "German",
  "en": "English",

  "immoMap": "ImmoExplorer",
  "immoCalculator": "ImmoInvest",
  "homepage": "Homepage",

  "yes": "Yes",
  "no": "No",

  // calculator
  // counties
  "badenWuerttemberg": "Baden-Württemberg",
  "bayern": "Bavaria",
  "berlin": "Berlin",
  "brandenburg": "Brandenburg",
  "bremen": "Bremen",
  "hamburg": "Hamburg",
  "hessen": "Hesse",
  "mecklenburgVorpommern": "Mecklenburg-Western Pomerania",
  "niedersachsen": "Lower Saxony",
  "nordrheinWestfalen": "North Rhine-Westphalia",
  "rheinlandPfalz": "Rhineland-Palatinate",
  "saarland": "Saarland",
  "sachsen": "Saxony",
  "sachsenAnhalt": "Saxony-Anhalt",
  "schleswigHolstein": "Schleswig-Holstein",
  "thueringen": "Thuringia",

  //KPIs
  "immoPrice": "Property Price",
  "coldRent": "Cold Rent",
  "livingArea": "Living Area",
  "realestateAgentRate": "Real Estate Agent Rate",
  "interestRate": "Interest Rate",
  "equity": "Equity",
  "operatingCosts": "Monthly Operating Costs",
  "allocableOperatingCosts": "Monthly Allocable Operating Costs",
  "counties": "Counties",
  "netOperatingIncome": "Net Operating Income",
  "netRentalYield": "Net Rental Yield",
  "cocReturn10": "Cash-on-Cash Return",
  "plotSize": "Plot Size",
  "landShare": "Land Share",
  "localRentValue": "Local Comparative Rent",
  "yearOfConstruction": "Year of Construction",
  "repaymentRate": "Repayment Rate",
  "purchaseDate": "Purchase Date",
  "landValueAssessment": "Land Value Assessment",
  "marginTaxRate": "Marginal Tax Rate",
  "landValue": "Land Value",
  "depreciationStartValue": "Depreciation Start Value",
  "depreciationRate": "Depreciation Rate",
  "taxes10": "Taxes over 10 Years",
  "repaymentCoverageRatio10": "Repayment Coverage Ratio over 10 Years",
  "avgNetCashflow10": "Ø Netto-Cashflow over 10 years",
  "preTaxProfit10": "Total Profit Before Taxes Over 10 years",
  "depreciationDegressiveEligible": "Degressive Depreciation Eligible?",
  "annuity": "Annuity",
  "creditDuration": "Credit Duration",
  
  "avgInterest10": "Ø Interest Rate Over 10 Years",
  "avgRepaymentRate10": "Ø Repayment Rate Over 10 years",
  "savedTaxes": "Saved Taxes",
  "taxes": "Taxes",
  "cashflow": "Cashflow",
  "income": "Income",
  "cost": "Cost",

  "yearlyRent": "Yearly Rent",
  "effectiveRent": "Effective Yearly Rent",
  "grossRentalYield": "Gross Rental Yield",
  "loanAmount": "Loan Amount",
  "ltvRatio": "Loan-to-Value Ratio (LTV)",
  "equityPercentage": "Equity Percentage",
  "costRealestateAgent": "Real Estate Agent Fees",
  "costNotary": "Notary Fees",
  "costLandRegister": "Land Registry Costs",
  "costCountyTaxes": "Property Purchase Tax",
  "costPerSquareMeter": "Cost Per Square Meter",
  "vacancy": "Vacancy",
  "nonAllocableOperatingCosts": "Monthly Non-Allocable Operating Costs",

  // Headers:
  "incomeAndYield": "Income & Yield",
  "financing": "Financing",
  "purchaseAndRelatedCosts": "Purchase & Purchse-Related Costs",
  "operatingAndVacancyCosts": "Operating And Vacancy Costs",
  "taxationInformation": "Taxation Information",

  // Overlay
  valueAndPotential: 'Value & Potential',
  marketValueAnalysis: 'Market Value Analysis',
  buildingAndPropertyData: 'Building & Property Data',
  accessibilityAndServices: 'Accessibility & Services',
  risksAndLiabilities: 'Risks & Liabilities',
  buildingData: 'Building Data',
  propertyData: 'Property Data',
  unknownAddress: 'Unknown Address',
  "distance_childcare": "Childcare Centers",
  "distance_healthcare": "Healthcare Services",
  "distance_higher_education": "Higher School",
  "distance_park": "Park",
  "distance_public_station": "Public Station",
  "distance_railway_line": "Railway Line",
  "distance_school": "School",
  "distance_supermarket": "Supermarket",
  "distance_thoroughfare": "Thoroughfare",
  "brw_2024": "Land Value Assessment",
  property_value: "Property Value",
  property_value_lower: "Property Value Min.",
  property_value_upper: "Property Value Max.",


  "show_more": "Show More",
  "show_less": "Show Less",

  "propertyInformation": "Property Information",
  "rentalAndIncome": "Rental & Income Information",
  "financialDetails": "Financial Details",
  "taxAndValuation": "Tax & Valuation Information",
  "geographicalInformation": "Geographical Informationen",

  // Informational Text Elements:
  "evaluationCalculatorResultsLow": "Seems Like A Bad Choice",
  "evaluationCalculatorResultsMedium": "Could Be Okay, Please Check",
  "evaluationCalculatorResultsHigh": "Seems Like A Good Investment",

  "inputParameter": "Input Parameters",
  "outputParameter": "Calculated KPIs",

  // Flurstücke Layer
  "Immo_Layer": "Real Estate Data",
  "landParcels": "Land Parcels",
  "flurstid": "Parcel ID",
  "ags": "Official Community Code",
  "land": "County ID",
  "gemarkungsnummer": "District Number",
  "flur": "Field",
  "flurschl": "Parcel Key",
  "zaehler": "Numerator",
  "nenner": "Denominator",
  "lagebeztxt": "Location Description",
  "tntxt": "Type Of Use",
  "created_at": "Created At",
  "flurstueck_area": "Parcel Area",

  //Homepage
  amountOfBuildings:"Amount Of Buildings",
  amountOfParcels:"Amount Of Parcels",
  propsPerBuilding:"Amount Of Props Per Building",
  guideOverview: 'Guide Overview',

  //Buttons
  switch2D: "Switch to 2D view",
  switch3D: "Switch to 3D view",

  // Info Texts
  "immoPriceInfo": 
    "The property price is the total amount you have paid or plan to pay for the property. This price refers exclusively to the purchase price of the property without additional costs such as agent fees, notary fees, or taxes.",
  "livingAreaInfo": 
    "The living area of the property in square meters includes all habitable rooms, including balconies and gardens, but excludes basements or garages.",
  "plotSizeInfo": 
    "The plot size in square meters is the area of the land on which the property is located. It refers to the plot size in square meters. This value is needed for depreciation calculation.",
  "landShareInfo": 
    "The land share indicates the proportion of the land your property owns. Please provide this share as a percentage. You can find this value in the partition deed of the building.",
  "yearOfConstructionInfo": 
    "The year of construction of the building indicates when the property was originally built. Renovations or major refurbishments are not relevant here.",
  "coldRentInfo": 
    "The monthly cold rent is the rent paid for the property without operating costs, heating costs, or additional fees.",
  "operatingCostsInfo": 
    "The monthly operating costs are the property’s operating expenses and include additional monthly costs to the cold rent such as heating, management fees, waste disposal, water, sewage, and insurance.",
  "allocableOperatingCostsInfo": 
    "The monthly allocable operating costs are the portion of operating costs attributable to your property, but which can be passed on to tenants. This includes, for example, heating costs, insurance, or property taxes.",
  "localRentValueInfo": 
    "The local comparative rent is the rent price that comparable properties in the same area achieve. You can find this value in our ImmoExplorer or in the rent index of the city or municipality.",
  "vacancyInfo": 
    "Vacancy indicates the percentage of time the property is unleased or empty. This value is deducted from the cold rent income. Vacancy typically occurs during tenant changes or renovations.",
  "equityInfo": 
    "Equity is the portion of the purchase price that you have paid without external financing.",
  "interestRateInfo": 
    "The interest rate indicates the annual percentage rate you need to pay for the borrowed capital. This is the effective annual interest rate.",
  "repaymentRateInfo": 
    "The repayment rate is the percentage of the loan you repay regularly.",
  "realestateAgentRateInfo": 
    "The real estate agent fee rate is the commission the real estate agent receives for the purchase of the property, expressed as a percentage of the purchase price.",
  "purchaseDateInfo": 
    "The purchase date indicates when the purchase contract was concluded. This value is important to calculate the portion you can depreciate for the year in which the property was acquired. The date of the notarized purchase agreement is decisive here.",
  "landValueAssessmentInfo": 
    "The land value assessment indicates the value of the property based on the current valuation or an expert opinion. You can look up the value in our ImmoExplorer for each property and transfer it to the calculator.",
  "marginTaxRateInfo": 
    "The marginal tax rate is the tax rate applied to every additional euro you earn. This rate is calculated based on the taxable income (zvE) shown in your most recent tax return. You can calculate the exact marginal tax rate on the website of the Federal Ministry of Finance.",
  "depreciationDegressiveEligibleInfo": 
    "The possibility of applying a degressive depreciation indicates whether the property can be considered for tax purposes under degressive depreciation, allowing a higher value reduction in the first years. This option was created under the Growth Opportunities Act and allows degressive depreciation with a 5% residual value depreciation for a period of up to 6 years. This regulation is temporarily available for a limited time.",
  "countiesInfo": 
    "Select the federal state where your property or investment is located. This is important for tax assessment and regional taxes."

};

export default enTranslations;
