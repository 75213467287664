import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import colors from '../../utils/colors';
import { translate } from '../../utils/translationManager';
import { useLanguage } from '../../context/LanguageContext';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OutputCalculatorSideChart = ({ data, keys, header }) => {
  const { language } = useLanguage();
  console.log('Daten: ', data);

  // If `data` is null or undefined, return early or show a fallback UI
  if (!data) {
    return (
      <div
        style={{
          padding: '20px',
          backgroundColor: colors.backgroundContainer,
          borderRadius: '15px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          margin: '30px auto 0',
          fontFamily: 'Arial, sans-serif',
          textAlign: 'center',
        }}
      >
        <span>{translate('No data available', language)}</span>
      </div>
    );
  }

  // Safely access `data` values using optional chaining and fallback to 0 if undefined
  const avgInterest10Value = data?.avgInterest10?.value || 0;
  const nonAllocableOperatingCostsValue = (data?.nonAllocableOperatingCosts?.value || 0) * 12; // multiplying by 12 as per original logic
  const avgRepaymentRate10Value = data?.avgRepaymentRate10?.value || 0;
  const effectiveRentValue = data?.effectiveRent?.value || 0;
  const avgNetCashflow10Value = data?.avgNetCashflow10?.value || 0;
  const avgTaxes10Value = data?.avgTaxes10?.value || 0;

  // Determine where to place avgSavedTaxes10 based on its value
  const incomeValueTaxes = avgTaxes10Value < 0 ? Math.abs(avgTaxes10Value) : 0; // use positive value for income if negative
  const costValueTaxes = avgTaxes10Value >= 0 ? avgTaxes10Value : 0; // use the value directly for costs if positive
  const taxLabel = incomeValueTaxes > 0 ? 'savedTaxes' : costValueTaxes >= 0 ? 'taxes' : '';

  // Chart data setup
  const xAxisLabels = [translate('income', language), translate('cost', language), translate('cashflow', language)];

  const chartData = {
    labels: xAxisLabels,
    datasets: [
      {
        label: translate('avgInterest10', language),
        data: [0, avgInterest10Value, 0],
        backgroundColor: colors.chartColors.cat1,
      },
      {
        label: translate('nonAllocableOperatingCosts', language),
        data: [0, nonAllocableOperatingCostsValue, 0],
        backgroundColor: colors.chartColors.cat2,
      },
      {
        label: translate('avgRepaymentRate10', language),
        data: [0, avgRepaymentRate10Value, 0],
        backgroundColor: colors.chartColors.cat3,
      },
      {
        label: translate('effectiveRent', language),
        data: [effectiveRentValue, 0, 0],
        backgroundColor: colors.chartColors.cat4,
      },
      {
        label: translate(taxLabel, language),
        data: [incomeValueTaxes, costValueTaxes, 0],
        backgroundColor: colors.chartColors.cat5, // Different color for Saved Taxes
      },
      {
        label: translate('avgNetCashflow10', language),
        data: [0, 0, avgNetCashflow10Value], // This represents the net cash flow
        backgroundColor: colors.chartColors.cat6, // Neutral color for Net Cashflow
      },
    ],
  };

  // Chart configuration
  const config = {
    type: 'bar',
    data: chartData,
    options: {
      responsive: true,
      scales: {
        x: {
          stacked: true, // Stacked on the x-axis
        },
        y: {
          stacked: true, // Stacked on the y-axis
          ticks: {
            // Custom tick formatting for German notation
            callback: function(value) {
              return new Intl.NumberFormat('de-DE').format(value) + ' €'; // German number formatting with Euro symbol
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false, // Hide the default legend
        },
        title: {
          display: false,
          text: 'Einkommen vs Kosten und NettoCashflow',
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              // Get the dataset label (property name) and the raw value
              const label = translate(tooltipItem.dataset.label,language) || ''; // e.g., "avgInterest10"
              const value = tooltipItem.raw; // Raw value of the data point
              
              // Format the value as "1.020,32 €"
              const formattedValue = new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(value) + ' €';
              
              // Return the label and the formatted value in the tooltip
              return label + ': ' + formattedValue;
            }
          }
        }
      },
    },
  };

  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        margin: '30px auto 0',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* Render the header if provided */}
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            marginBottom: '20px',
            color: colors.headerText,
          }}
        >
          {translate(header, language)}
        </div>
      )}
      {/* Render the Bar chart */}
      <Bar {...config} />

      {/* Custom Legend Table */}
      <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse', fontSize: 16 }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left', padding: '5px', borderBottom: '2px solid #ddd' }}>{translate('label', language)}</th>
            <th style={{ textAlign: 'left', padding: '5px', borderBottom: '2px solid #ddd' }}>{translate('color', language)}</th>
          </tr>
        </thead>
        <tbody>
          {chartData.datasets.map((dataset, index) => (
            <tr key={index}>
              <td style={{ padding: '5px', borderBottom: '1px solid #ddd' }}>{dataset.label}</td>
              <td style={{ padding: '5px', borderBottom: '1px solid #ddd' }}>
                <span style={{ display: 'inline-block', width: '20px', height: '20px', backgroundColor: dataset.backgroundColor }} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OutputCalculatorSideChart;
