import colors from './colors'

export const createFilteredLayer = (
  layerId,
  sourceId,
  sourceLayer,
  layerType,
  is2DView,
  mainFilter,
  paintProps = {}, // New parameter for paint properties
  filterProps = null,
  filterValues = null,
  initialVisibility = 'none', //Layer visibility always on, change if wanting to change visibility
  
) => {
  console.log(
    'LALALA name: layerId:',
    layerId,
    '; sourceId:',
    sourceId,
    '; sourceLayer:',
    sourceLayer,
    '; layerType:',
    layerType,
    '; is2DView:',
    is2DView,
    '; mainFilter:',
    mainFilter,
    '; paintProps:',
    paintProps,
    '; filterProps:',
    filterProps,
    '; filterValues:',
    filterValues,
    '; initialVisibility:',
    initialVisibility
  );
  let filter = null

  if (filterProps && filterValues) {
    // Convert single filterProp and filterValues to arrays for consistency
    const propsArray = Array.isArray(filterProps) ? filterProps : [filterProps]
    const valuesArray = Array.isArray(filterValues)
      ? filterValues
      : [filterValues]

    // Build filters for each prop/values
    filter = [
      'all',
      ...propsArray.map((prop, index) => {
        const value = valuesArray[index]

        if (Array.isArray(value)) {
          if (
            value.length === 2 &&
            typeof value[0] === 'number' &&
            typeof value[1] === 'number'
          ) {
            // Range filter for numeric values (between two numbers)
            return ['all', ['>=', prop, value[0]], ['<=', prop, value[1]]]
          } else {
            // "in" filter for multiple values
            return ['in', prop, ...value]
          }
        } else {
          // "equals" filter for a single value
          return ['==', prop, value]
        }
      })
    ]
  }

  const createLayerPaint = (layerType, is2DView, mainFilter, paintProps = {}) => {
 
    let paint = {};
  
    if (layerType === 'fill-extrusion') {
      paint = {
        'fill-extrusion-color': [
          'match',
          ['get', mainFilter],
          ...Object.entries(colors.colorMappingBuildingType).flat(),
          '#CCCCCC' // Default color if no match
        ],
        'fill-extrusion-height': is2DView ? 0 : ['get', 'measuredheight'],
        ...paintProps // Spread the custom paint properties
      };
    } else {
      // Handle other layer types if needed
      paint = paintProps
    }
  
    return paint;
  };

  const layerPaint = createLayerPaint(layerType, is2DView, mainFilter, paintProps);

  return {
    id: layerId,
    type: layerType,
    source: sourceId,
    'source-layer': sourceLayer,
    paint: layerPaint,
    layout: {
      visibility: initialVisibility
    },
    ...(filter ? { filter } : {}) // Add filter only if it exists
  }
}

// Multi-value filter (for 'building_group_type' being either 'residential' or 'business')
// const multiTypeLayer = createFilteredLayer('multiTypeLayer', 'sourceId', 'sourceLayer', 'building_group_type', ['residential', 'business']);

// Single value filter (for 'building_group_type' being 'residential')
// const residentialLayer = createFilteredLayer('residentialLayer', 'sourceId', 'sourceLayer', 'building_group_type', 'residential');

// Numeric range filter (for 'height' between 10 and 50)
// const heightRangeLayer = createFilteredLayer('heightRangeLayer', 'sourceId', 'sourceLayer', 'measuredheight', [10, 50]);

// Multiple filters (e.g., 'building_group_type' and a range for 'height')
/* const multiFilterLayer = createFilteredLayer(
    'multiFilterLayer',
    'sourceId',
    'sourceLayer',
    ['building_group_type', 'measuredheight'],
    [['residential', 'business'], [10, 50]]
);
*/

// No filter
// const allBuildingsLayer = createFilteredLayer('allBuildingsLayer', 'sourceId', 'sourceLayer');
