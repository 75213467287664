import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import useDeviceType from '../../hooks/useDeviceType'
import KPIBarsOverlay from './KPIBarsOverlay'
import InfoListOverlay from './InfoListOverlay'
import GraphicsFrameOverlay from './GraphicsFrameOverlay'
import colors from '../../utils/colors'
import './Overlay.css'
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

const Overlay = ({
  overlayData,
  overlayParcelsData,
  closeOverlay,
  isSidebarOpen
}) => {
  const { isMobile, isTablet } = useDeviceType()
  const { language } = useLanguage()
  console.log('overlayData ', overlayData)
  console.log('overlayParcelsData ', overlayParcelsData)

  const [selectedTab, setSelectedTab] = useState(0)

  const isOverlayDataAvailable =
    overlayData && Object.keys(overlayData).length > 0
  const isOverlayParcelsDataAvailable =
    overlayParcelsData && Object.keys(overlayParcelsData).length > 0

  // On mount, check if there's a previously selected tab stored in localStorage
  useEffect(() => {
    const savedTab = localStorage.getItem('selectedTab')
    if (savedTab !== null) {
      setSelectedTab(Number(savedTab))
    }
  }, [])

  // Check if the selected tab's data is available, switch to the other if necessary
  useEffect(() => {
    if (
      selectedTab === 0 &&
      !isOverlayDataAvailable &&
      isOverlayParcelsDataAvailable
    ) {
      setSelectedTab(1)
      localStorage.setItem('selectedTab', 1)
    } else if (
      selectedTab === 1 &&
      !isOverlayParcelsDataAvailable &&
      isOverlayDataAvailable
    ) {
      setSelectedTab(0)
      localStorage.setItem('selectedTab', 0)
    }
  }, [isOverlayDataAvailable, isOverlayParcelsDataAvailable, selectedTab])

  // Function to handle tab change and save it to localStorage
  const handleTabSelect = index => {
    setSelectedTab(index)
    localStorage.setItem('selectedTab', index) // Save the selected tab
  }

  const tabListStyles = {
    display: 'flex',
    justifyContent: 'space-around',
    borderBottom: `2px solid ${colors.text1}`,
    marginBottom: '10px',
    paddingBottom: '2px',
    borderRadius: '8px 8px 8px 8px'
  }

  const tabStyles = {
    flex: 1,
    textAlign: 'center',
    padding: '10px 0',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: colors.text1,
    transition: 'border-color 0.3s',
    borderRadius: '8px 8px 8px 8px',
    boxShadow: '0 10 20px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff'
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: isMobile ? '0' : '70px',
        left: isMobile ? '0' : isSidebarOpen ? '370px' : '10px',
        width: isMobile ? '100%' : isTablet ? '40%' : '25%',
        maxWidth: isMobile ? 'none' : '425px',
        height: isMobile ? '100%' : 'calc(100% - 90px)',
        background: colors.background,
        paddingRight: isMobile ? '10px' : '20px',
        paddingBottom: isMobile ? '10px' : '20px',
        paddingLeft: isMobile ? '10px' : '20px',
        paddingTop: isMobile ? '0px' : '0px',
        borderRadius: isMobile ? '0' : '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        zIndex: 2,
        boxSizing: 'border-box',
        overflow: 'auto',
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        transition: 'left 0.3s, top 0.3s'
      }}
    >
      {/* Close button in the top-right corner */}
      <h3
        style={{
          padding: '5px 60px 0 0',
          whiteSpace: 'pre-wrap',
          minHeight: 60,
          fontFamily: 'Arial, sans-serif',
          fontSize: 24,
          display: 'flex', // Use flexbox for alignment
          alignItems: 'center', // Center vertically
          justifyContent: 'flex-start',
          fontWeight: 'bold'
        }}
      >
        {overlayData &&
        overlayData.properties &&
        overlayData.properties.addr_street &&
        overlayData.properties.addr_housenumber ? (
          <>
            {overlayData.properties.addr_street}{' '}
            {overlayData.properties.addr_housenumber}
            <br />
            {overlayData.properties.addr_postcode}{' '}
            {overlayData.properties.addr_city}
          </>
        ) : overlayParcelsData &&
          overlayParcelsData.properties &&
          overlayParcelsData.properties.lagebeztxt ? (
          <span>{overlayParcelsData.properties.lagebeztxt}</span>
        ) : (
          <span>{translate('unknownAddress', language)}</span>
        )}
      </h3>
      <Tabs
        selectedIndex={selectedTab}
        onSelect={handleTabSelect}
        style={{
          fontFamily: 'Arial, sans-serif',
          borderRadius: '8px 8px 8px 8px'
        }}
      >
        <TabList style={tabListStyles}>
          <Tab disabled={!isOverlayDataAvailable} style={tabStyles}>
          {translate('buildingData', language)}
          </Tab>
          <Tab disabled={!isOverlayParcelsDataAvailable} style={tabStyles}>
            {translate('propertyData', language)}
          </Tab>
        </TabList>

        <TabPanel
          //Gebäudedaten
          style={{
            padding: '10px 0',
            color: colors.text
          }}
        >
          <KPIBarsOverlay
            overlayData={overlayData}
            header={translate('valueAndPotential', language)}
          />

          <GraphicsFrameOverlay
            overlayData={overlayData}
            values={[
              { value: 'property_value', unit: '€ / m²', steps: 0.01 },
              { value: 'property_value_lower', unit: '€ / m²', steps: 0.01 },
              { value: 'property_value_upper', unit: '€ / m²', steps: 0.01 }
            ]}
            header={translate('marketValueAnalysis', language)}
          />

          <InfoListOverlay
            overlayData={overlayData}
            values={[
              { value: 'measuredheight', unit: 'm', steps: 0.1 },
              { value: 'building_group_type', unit: '', steps: 1 },
              { value: 'brw_2024', unit: '€', steps: 1 }
            ]}
            header={translate('buildingAndPropertyData', language)}
            collapse={3}
          />

          <InfoListOverlay
            overlayData={overlayData}
            values={[
              { value: 'distance_park', unit: 'm', steps: 1 },
              { value: 'distance_public_station', unit: 'm', steps: 1 },
              { value: 'distance_supermarket', unit: 'm', steps: 1 },
              { value: 'distance_healthcare', unit: 'm', steps: 1 },
              { value: 'distance_childcare', unit: 'm', steps: 1 },
              { value: 'distance_school', unit: 'm', steps: 1 },
              { value: 'distance_higher_education', unit: 'm', steps: 1 }
            ]}
            header={translate('accessibilityAndServices', language)}
            collapse={3}
          />
          <InfoListOverlay
            overlayData={overlayData}
            values={[
              { value: 'distance_railway_line', unit: 'm', steps: 1 },
              { value: 'distance_thoroughfare', unit: 'm', steps: 1 }
            ]}
            header={translate('risksAndLiabilities', language)}
            collapse={3}
          />
        </TabPanel>

        <TabPanel
          //Grundstücksdaten
          style={{
            padding: '10px 0',
            color: colors.text
          }}
        >
          <InfoListOverlay
            overlayData={overlayParcelsData}
            values={[
              { value: 'flurstid', unit: '', steps: 1 },
              { value: 'ags', unit: '', steps: 1 },
              { value: 'land', unit: '', steps: 1 },
              { value: 'gemarkungsnummer', unit: '', steps: 1 },
              { value: 'flur', unit: '', steps: 1 },
              { value: 'flurschl', unit: '', steps: 1 },
              { value: 'zaehler', unit: '', steps: 1 },
              { value: 'nenner', unit: '', steps: 1 },
              { value: 'lagebeztxt', unit: '', steps: 1 },
              { value: 'tntxt', unit: '', steps: 1 },
              { value: 'created_at', unit: '', steps: 1 },
              { value: 'flurstueck_area', unit: 'm²', steps: 0.1 }
            ]}
            header={'Flurstücksdaten'}
          />
        </TabPanel>
      </Tabs>
      <button
        onClick={closeOverlay}
        style={{
          position: 'absolute',
          top: isMobile ? '10px' : '10px',
          right: isMobile ? '15px' : '20px',
          height: '50px',
          width: '50px',
          background: colors.background,
          border: '2px solid',
          borderColor: colors.icon,
          borderRadius: '10%',
          padding: '0px',
          fontSize: '28px',
          cursor: 'pointer',
          color: colors.icon
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  )
}

export default Overlay
