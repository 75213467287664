import React from 'react'
import colors from '../../utils/colors'
import config from '../../utils/config';
import { faMapLocationDot, faBars, faCalculator, faHouse } from '@fortawesome/free-solid-svg-icons';
import { translate } from '../../utils/translationManager'
import { useLanguage } from '../../context/LanguageContext'
import AppElement from './AppElement'

const AppsNavbar = ({ header }) => {
  const { language } = useLanguage()
  return (
    <div
      style={{
        padding: '20px',
        backgroundColor: colors.backgroundContainer,
        borderRadius: '15px',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
        width: '90%',
        margin: '30px auto 0'
      }}
    >
      {header && (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '20px',
            color: colors.headerText,
            width: '100%'
          }}
        >
          <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <AppElement appName={translate('homepage', language)} link={config.apps.homepage} logo={faHouse} />
            <AppElement appName={translate("immoMap", language)} link={config.apps.immoMap} logo={faMapLocationDot} />
            <AppElement appName={translate('immoCalculator', language)} link={config.apps.immoCalculator} logo={faCalculator} />
          </div>
        </div>
      )}
    </div>
  )
}

export default AppsNavbar
